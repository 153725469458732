const ruPages = {
    accessFeatures: {
        title: "Доступы",
        edit: "Редактировать доступ",
        create: "Создать доступ",
        delete: "Удалить доступ",
        menu: {
            title: "Меню",
        },
        access: {
            title: "Доступы",
            noData: "нет доступов",
        },
        categories: {
            title: "Категории",
            fields: {
                id: "ID",
                title: "Название",
                name: "Системное имя",
            },
        },
        users: {
            title: "Пользователи",
        },
        roles: {
            title: "Роли",
            fields: {
                accessFeatures: "Доступы",
            },
        },
        innerName: "Внутреннее наименование",
    },
    analytics: {
        totalPrice: "Сумма",
        cnt: "шт.",
        rub: "руб.",
        tradePointTitle: "Кофейня",
        ratingTitle: "Рейтинг по выборке",
        analyticsIndicator: "Индикатор сравнения",
        filter: {
            date: {
                title: "Дата",
            },
            dateStart: "Начало периода",
            dateEnd: "Конец периода",
            previousDateStart: "Начало предыдущего периода",
            previousDateEnd: "Конец предыдущего периода",
            tradePointsIds: "Кофейни",
            showButton: {
                title: "Показать",
            },
            order: {
                title: "Сортировка",
                alphaAsc: "От А до Я",
                alphaDesc: "От Я до А",
                rateAsc: "От лучших к худшим",
                rateDesc: "От худших к лучшим",
                dateAsc: "От старых к новым",
                dateDesc: "От новых к старым",
            },
            search: {
                title: "Поиск",
            },
        },
        proceedsSum: {
            title: "Выручка",
            unit: "%p#rubs#",
        },
        averageCheckSum: {
            title: "Средний чек",
            unit: "%p#rubs#",
        },
        receiptsQuantity: {
            title: "Количество чеков",
            unit: "%p#receipts#",
        },
        okkComplaintsCount: {
            title: "Количество жалоб",
            unit: "%p#complaints#",
            currentValuesTitle: "Количество жалоб за текущий период",
            previousValuesTitle: "Количество жалоб за предыдущий период",
            chartTitle: "Жалобы за текущий период",
            previousChartTitle: "Жалобы за предыдущий период",
            chartUnit: "кол-во",
        },
        okkGratitudesCount: {
            title: "Количество благодарностей",
            unit: "%p#complaints#",
            currentValuesTitle: "Количество благодарностей за текущий период",
            previousValuesTitle: "Количество благодарностей за предыдущий период",
            chartTitle: "Благодарности за текущий период",
            previousChartTitle: "Благодарности за предыдущий период",
            chartUnit: "кол-во",
        },
        okkComplaintsCategoriesPercentage: {
            title: "Категории обращений гостей",
            unit: "%",
            valuesTitle: "Процентное соотношение категорий за текущий период",
            previousValuesTitle: "Процентное соотношение категорий за предыдущий период",
        },
        okkComplaintStatusLog: {
            title: "Отчет по статусам обращений",
            complaintId: "Идентификатор обращений",
            tradePointName: "Кофейня",
            inspector: "Проверяющий",
            complaint: "Обращение",
            inWorkTime: 'Общее время в статусе "В работе"',
            waitSolutionTime: 'Общее время в статусе "Ожидает решения"',
        },
        qualityControl: {
            title: "Баллы несоответствия – общие",
            unit: "%p#scores#",
            onlyBad: "Только кофейни с нарушениями",
            subUnit: "%p#offences#",
        },
        technicalQualityControl: {
            title: "Баллы несоответствия – тех.состояние",
            unit: "%p#scores#",
            onlyBad: "Только кофейни с нарушениями",
            subUnit: "%p#offences#",
        },
        discrepancyIndex: {
            title: "Индекс несоответствия",
            unit: "%",
            onlyBad: "Только с превышением порога",
            subUnits: ["%p#scores#", "%p#receipts#"],
        },
        markingsReport: {
            title: "Отчет по маркировкам",
            markingName: "Маркировка",
            tradePoint: "Кофейня",
            createdAt: "Изготовлено",
            sellBy: "Годен до",
            startDate: "Начало периода",
            endDate: "Конец периода",
            search: "Маркировка",
            errors: {
                getTradePointsError: "Не удалось получить кофейни для формирования отчета",
                getReportError: "Не удалось получить данные для формирования отчета",
            },
        },
        mobileApp: {
            title: "Мобильное приложение",
            unit: "%p#scores#",
            onlyBad: "Только плохие ( оценка < 4.78 )",
            subUnit: "%p#feedback#",
            percent: "% оцененных заказов",
        },
        nps: {
            title: "NPS",
            unit: "%",
            download: "Скачать Excel",
            conclusion: "Суммарно по сети",
            fields: {
                point: "Оценка",
                count: "Количество",
                percentage: "Проценты",
            },
        },
        tradePoint: {
            proceedsSum: {
                title: "Аналитика по выручке",
                unit: "%p#rubs#",
                subUnit: "",
            },
            feedbackIndexes: {
                thanksCategory: "Благодарность",
                focusCheckCategory: "Фокус месяца - проверки",
                tradePoint: "Кофейня",
                date: "Дата",
                search: "Поиск",
                title: "Индексы обратной связи",
                gratitudeIndex: "Индекс благодарностей",
                complaintIndex: "Индекс жалоб",
                noRecords: "Данные еще не загружены. Попробуйте немного позже",
            },
            averageCheckSum: {
                title: "Аналитика по чекам",
                unit: "%p#rubs#",
                subUnit: "%p#receipts#",
            },
            receiptsQuantity: {
                title: "Аналитика по чекам",
                unit: "%p#rubs#",
                subUnit: "%p#receipts#",
            },
            complaintIndex: {
                title: "Аналитика по обращениям",
                unit: "%p#scores#",
                subUnit: "%p#offences#",
                mobileAppDate: "отзыв",
                description: "Описание",
                complaintType: "Обращения",
                complaintType0: "Все",
                complaintType1: "С нарушениями",
                complaintType2: "Для расчёта индекса",
            },
            qualityControl: {
                title: "Аналитика по обращениям",
                unit: "%p#scores#",
                subUnit: "%p#offences#",
            },
            technicalQualityControl: {
                title: "Аналитика по обращениям - тех. состояние",
                unit: "%p#scores#",
                subUnit: "%p#offences#",
            },
            mobileApp: {
                title: "Аналитика мобильного приложения",
                unit: "%p#scores#",
                orderNumber: "Номер заказа",
                comment: "Комментарий",
                onlyBad: "Только плохие ( оценка < 3 )",
                search: "Телефон / Имя гостя / Номер заказа",
                searchManager: "Номер заказа",
                loading: "Идет получение дополнительной информации. Подождите",
                orderDetails: {
                    title: "Подробности отзыва",
                    orderNumber: "Номер заказа",
                    createdDate: "Дата отзыва",
                    clientName: "Имя гостя",
                    userPhone: "Номер гостя",
                    userComment: "Отзыв",
                    dishes: "Позиции",
                },
            },
            checkList: {
                title: "Аналитика чек-листов",
                unit: "%",
                onlyBad: "Только плохие листы ( процент < 30% )",
                passed: "Пройден",
                notPassed: "Не пройден",
            },
            cln: {
                title: "Аналитика КЛН",
                unit: "%",
                onlyBad: "Только плохие листы ( процент < 30% )",
            },
        },
        tradePointDashboard: {
            title: "Общая аналитика по кофейне",
            filter: {
                date: "Дата",
                tradePoint: "Кофейня",
            },
            tooltips: {
                rating: "Рейтинг, относительно других кофеен",
                value: "Детально",
                primary: "Как в предыдущем месяце",
                success: "Лучше чем в предыдущем месяце",
                error: "Хуже чем в предыдущем месяце",
            },
            ratingSuffix: "-ая",
        },
    },
    appChecklist: {
        title: "Приложение для Чек-листов",
        add: {
            fields: {
                version: "Номер версии",
                description: "Описание",
                file: "APK файл",
            },
        },
        addTitle: "Добавить новую версию",
        fields: {
            version: "Номер версии",
            id: "ID",
            isActive: "Активная",
            imageId: "Скачать",
        },
        setActive: 'Установить версию "%s" в качестве активной?',
    },
    cities: {
        listTitle: "Список городов",
        editTitle: "Редактирование города",
        addTitle: "Добавление города",
        fields: {
            name: "Название",
            code: "Код города",
            timeZone: "Временная зона",
            region: "Регион",
        },
    },
    clients: {
        reports: {
            title: "Отчеты",
            bonus: "Отчет по начислениям",
            category: "Отчет по гостям",
            formSave: "Поиск",
            fields: {
                dateRange: "Период отчета",
                startDate: "С",
                endDate: "По",
                categoryId: "Статья",
                categoryIds: "Категория",
            },
            errors: {
                downloadReport: "Не удалось получить отчет!",
            },
        },
        bonus: {
            title: "Бонусные балы",
            titleGroup: "Бонусные балы массово",
            clear: "Сброс",
            phonesCount: "Внесено: %p#numbers#",
            fields: {
                phone: "Номер телефона",
                phones: "Номер телефонов",
                phonesHelp: "Одна строка - один номер. 79000000000",
                bonus: "Кол-во баллов",
                bonusHelp:
                    "Кол-во баллов у гостя будет дополнено до этой суммы. Не прибавится, а будет дополнено!",
                categoryId: "Статья",
                description: "Комментарий",
                startDate: "Дата мероприятия",
                department: "Инициатор/отдел",
                number: "Номер сертификата",
                type: "Тип пополнения",
            },
            result: {
                sum: "на сумму",
                isOk: "Начислено гостям",
                isNotOk: "Не удалось начислить",
            },
            errors: {
                addBonuses: "Не удалось начислить бонусные балы гостю!",
                phonesLength: "Укажите корректные номера телефонов",
            },
            success: {
                addBonuses: "Бонусные балы начислены",
                setBonuses: "Бонусные балы начислены %s гостям на сумму %s бонусов",
            },
        },
    },
    cookFactory: {
        orders: {
            noOrders: "Нет заказов",
            order: {
                addTitle: "Новый заказ",
                title: "Редактировать заказ",
                order: "Заказ",
                goods: "Товары",
                newStatus: "Новый заказ",
                createOrder: "Заказать",
                fields: {
                    orderDate: "Дата поставки",
                    orderNumber: "Номер заказа",
                    status: "Статус",
                    user: "Пользователь",
                    name: "Продукт",
                    measureName: "ед. изм.",
                    quantity: "Кол-во",
                },
                success: {
                    createTradePointOrder: "Заказ создан",
                    updateTradePointOrder: "Заказ изменен",
                },
                errors: {
                    getTradePointOrder: "Не удалось получить информацию о заказе!",
                    getTradePointPrice: "Не удалось получить список товаров!",
                    createTradePointOrder: "Не удалось создать заказ!",
                    updateTradePointOrder: "Не удалось изменить заказ!",
                    isModify: "Данный заказ не доступен для редактирования!",
                },
            },
            deleteConfirm: {
                title: "Удалить заказ",
                text: "Вы уверены, что хотите удалить заказ на %s?",
                okText: "Да, удалить",
            },
            fields: {
                createDate: "Дата создания",
                status: "Статус",
                user: "Пользователь",
                orderDate: "Дата поставки",
                orderNumber: "Номер заказа",
                actions: "Действия",
            },
            actions: {
                show: "Просмотр заказа",
                delete: "Удалить заказ",
                edit: "Изменить заказ",
            },
            success: {
                deleteTradePointOrder: "Заказ удален",
            },
            errors: {
                getTradePointOrders: "Не удалось получить список заказов!",
                deleteTradePointOrder: "Не удалось удалить заказ!",
                noTradePoints: "У вас нет доступных кофеен",
            },
        },
        personal: {
            title: "Мои заказы с фабрики кухни",
            showTitle: "Просмотр заказа",
            officeTradePointName: "Офис УК",
            tradePointFilter: "Место поставки",
        },
        tradePoints: {
            title: "Заказы для кофейни с фабрики кухни",
            showTitle: "Просмотр заказа",
        },
        iikoChecker: {
            code: "Укажите ваш табельный номер в Iiko",
            description:
                "Если вы знаете свой табельный номер - можете его указать. Если вы не знаете его, обратитесь к вашему менеджеру или в службу тех. поддержки",
            success: {
                checkIikoUserNewCode: "табельный номер добавлен к вашему профилю",
            },
            errors: {
                iikoUserCode: "Ваш табельный номер не внесен в базу портала.",
                checkIikoUserCode: "Не удалось получить Данные о пользователе в Iiko!",
                checkIikoUserNewCode: "Не удалось проверить данный табельный номер в Iiko!",
            },
        },
    },
    courses: {
        title: "Список курсов",
        addTitle: "Новый курс",
        editTitle: "Редактировать курс",
        fields: {
            title: "Курс",
            image: "Фото",
            description: "Описание",
        },
        success: {
            remove: "Курсы удалены",
        },
        errors: {
            loadData: "Ошибка при получении списка курсов!",
            remove: "Не удалось удалить курс!",
        },
        users: {
            add: "Добавить сотрудников",
            title: "Сотрудники",
            fields: {
                course: "Курс",
                user: "Сотрудник",
                tradePoint: "Кофейня",
                role: "Роль",
                passDate: "Дата прохождения",
            },
            status: "Сотрудники",
            status0: "Те кто не прошел",
            status1: "Те кто прошел",
            users: "Участники",
            saved: "Добавлено %s сотрудников",
            errors: {
                courseUsers: "Не удалось получить список сотрудников!",
                courseUsersAdd: "Не удалось сохранить данные на сервере",
            },
        },
    },
    dashboard: {
        title: "Дашборды",
        loading: "Загрузка...",
        noData: "Нет данных для отображения",
        fullScreen: "Во весь экран",
        selector: "Выберите дашборд",
        config: {
            title: "Настройка Дашборды",
            dashboard: "Дашборд",
            dashboardElement: "Элементы дашборда",
        },
        fields: {
            name: "Название",
            type: "Тип",
            url: "Ссылка",
            order: "Порядок",
            dashboardId: "Дашборд",
            displayDuration: "период обновления (в сек.)",
            description: "Описание",
        },
    },
    departments: {
        title: "Структурные подразделения",
        addTitle: "Новое подразделение",
        editTitle: "Редактировать подразделение",
        deleteConfirm: {
            title: "Удалить подразделение",
            text: 'Вы хотите удалить подразделение "%s"',
            okText: "Да, удалить",
        },
        fields: {
            title: "Название",
            parentId: "Родитель",
            tradePointId: "Кофейня",
            legalPersonId: "Юр. лицо",
        },
        errors: {
            getRecord: "Ошибка при получении данных!",
            save: "Ошибка при сохранении данных!",
            clearLegalPerson: "Нельзя удалить подразделение, т.к. оно связано с Юр. лицом",
            clearTradePoint: "Нельзя удалить подразделение, т.к. оно связано с кофейней",
            clearText: "Удалите зависимости, если хотите удалить подразделение",
            delete: "Ошибка при удалении подразделения!",
        },
        success: {
            save: "Данные сохранены",
        },
    },
    documents: {
        my: {
            title: "Мои документы",
            checkTitle: "Документы сотрудников на проверке",
            editTitle: "Редактировать документ",
            fields: {
                title: "Документ",
                createdDate: "Дата создания",
                updatedDate: "Дата последнего изменения",
            },
            steps: {
                viewTitle: "Просмотр документа",
                editTitle: "Редактировать документ",
                main: "Документ",
                history: "История согласований",
                statusDescription: "Комментарий",
                confirm: {
                    title: "Согласование документа",
                    confirm: "Согласовано",
                    confirmText: 'Вы ходите согласовать документ "%s"?',
                    confirmOkText: "Да, согласовать",
                    notConfirmOkText: "Да, Не согласовано",
                    notConfirm: "Не согласовано",
                    notConfirmText: 'Вы уверены, что ходите пометить документ "%s" как "Не согласованный"?',
                },
                edit: {
                    title: "Сохранить документ",
                    okText: "Да, сохранить",
                    text: 'Вы ходите сохранить документ "%s"?',
                    save: "Сохранить",
                },
                actions: {
                    show: "Просмотр",
                    edit: "Внести информацию",
                    confirm: "Подтвердить документ",
                    toEdit: "Вернуться к заполнению документа",
                    toFinish: "Отправить документ",
                },
                success: {
                    finish: "Вы закончили заполнение документа",
                    confirm: 'Документ "%s" согласован',
                    notConfirm: 'Документ "%s" помечен как Несогласован',
                    saveAnswer: "Ответ изменен",
                },
                errors: {
                    finish: "Не удалось закончить заполнение документа!",
                    confirm: "Не удалось подтвердить документ!",
                    saveAnswer: "Не удалось отредактировать ответ!",
                },
            },
            history: {
                createdUser: "Кто",
                prevStatus: "Предыдущий статус",
                status: "Новый статус",
            },
        },
        reports: {
            title: "Отчеты по документам",
            report: "Тип отчета",
            medicalBookReport: "Отчет по медицинским книжкам",
            error: "Не удалось получить отчет!",
        },
        templates: {
            title: "Шаблоны документов пользователей",
            editTitle: "Редактировать шаблон",
            addTitle: "Новый шаблон",
            userRoleFlag: "Роли пользователей",
            tabs: {
                main: "Основное",
                fields: "Поля документа",
            },
            success: {
                edit: "Шаблон изменен",
                add: "Создан новый шаблон",
                saveFieldEdit: "Поле %s изменено",
                saveFieldAdd: "Добавлено новое поле: %s",
                saveFieldsOrder: "Порядок полей изменен",
                removeField: "Удалено поле: %s",
            },
            errors: {
                getRecord: "Не удалось получить информацию о шаблоне!",
                save: "Не удалось отредактировать шаблон!",
                saveField: "Не удалось отредактировать поле %s!",
                saveFieldsOrder: "Не удалось изменить порядок полей!",
                removeField: "Не удалось удалить поле: %s",
            },
            questions: {
                addTitle: "Новое поле документа",
                editTitle: "Редактировать вопрос",
                isDragEnabled: "Сортировка вопросов",
                saveOrder: "Сохранить порядок вопросов",
            },
        },
        templateTypes: {
            title: "Типы шаблонов документов",
            editTitle: "Редактировать тип",
            addTitle: "Новый тип",
            tabs: {
                main: "Основное",
                processing: "Согласование",
            },
            processings: {
                title: "Настройка согласования",
                saveChanges: "Сохранить изменения",
                fields: {
                    action: "Действие",
                    userType: "Кто совершает",
                    nextStatus: "Новый статус",
                    errorStatus: "Статус если не согласовано",
                },
                errors: {
                    noRecords: "Укажите параметры для согласования",
                    statusNextStatus:
                        "Одна или более запись %s имеет одинаковые значения для Статус и Новый статус.",
                    notCreatedCount: 'Ожидается 1 запись со статусом "Новый", найдено: %s.',
                    successCount: 'Ожидается хотя бы 1 запись со статусом "Подтвержден".',
                    sequence:
                        "Нарушена последовательность. Для записи ID=%s с новым статусом = %s не найден Статус.",
                },
            },
            success: {
                edit: "Тип изменен",
                add: "Создан новый тип",
            },
            errors: {
                getRecord: "Не удалось получить информацию о типе шаблона!",
                save: "Не удалось отредактировать тип шаблона!",
                saveProcessings: "Не удалось отредактировать согласования для шаблона!",
            },
        },
    },
    equipmentRegister: {
        add: {
            title: "Новая запись",
            text: "Что хотите добавить?",
            type: "Тип оборудования",
            equipment: "Оборудование/Инвентарь",
        },
        remove: {
            titleEquipment: "Удалить оборудование",
            titleType: "Удалить тип оборудования",
            textEquipment: 'Вы хотите удалить оборудование "%s"?',
            textType: 'Вы хотите удалить тип оборудование "%s"?',
            okText: "Да, удалить",
        },
        success: {
            removeType: 'Тип "%s" удален',
            removeEquipment: 'Оборудование "%s" удалено',
        },
        errors: {
            checkParents: "У данного типа есть вложенные элементы. Удалите сначала их!",
            removeType: "Не удалось удалить тип!",
            removeEquipment: "Не удалось удалить оборудование!",
        },
    },
    fileShare: {
        title: "Файлы",
        toMain: "В корневую папку",
        config: {
            title: "Настройки",
            roles: {
                title: "Доступы по ролям",
                fields: {
                    roleId: "Роль",
                    folders: "Папки",
                },
                success: {
                    updateRole: "Доступ для роли изменен",
                },
                errors: {
                    updateRole: "Не получилось обновить роль!",
                },
            },
            accessFeatures: {
                title: "Уникальные доступы",
                fields: {
                    accessFeatureId: "Доступ",
                    folders: "Папки",
                },
                success: {
                    updateAccessFeature: "Доступ изменен",
                },
                errors: {
                    updateAccessFeature: "Не получилось обновить доступ!",
                },
            },
            linkManager: {
                title: "Управление ссылками",
                fields: {
                    folder: "Папка",
                    link: "ссылка",
                    createdUser: "Создатель",
                    createdDate: "Дата",
                    uid: "ID",
                },
            },
        },

        folderLink: {
            title: "Доступ по ссылке",
            getFolderLinkData: "Нет доступа",
        },
        errors: {
            noFolders: "Нет доступных для отображения папок",
        },
    },
    gpt: {
        title: "GPT чат",
        placeholder: "Введите сообщение...",
        send: "Отправить",
        clear: "Очистить чат",
        copied: "Скопировано",
        toCopy: "Копировать",
        typing: "Печатает...",
        historySaved: "История сохранена",
        historyError: "Ошибка при работе с историей",
        newChat: "Новый чат",
        newChatTitle: "Создание нового чата",
        chatName: "Название чата",
        cancel: "Отмена",
        create: "Создать",
        tokensRemaining: "Осталось токенов",
        variants: {
            gpt3_5: "Чат GPT 3.5",
            gpt4_0: "Чат GPT 4",
            gpt4_0o: "Чат GPT 4 Omni",
            imageGpt: "Dall-e",
            image: "Генератор изображений",
            fedia_gpt4_0o: "Федя программист",
            gpt4_o1Preview: "Чат GPT o1-Preview",
            gpt_o1Mini: "Чат GPT o1-Mini",
        },
        errors: {
            copy: "Не удалось скопировать текст",
        },
    },
    home: {
        title: "Главная страница",
        admin: {
            title: "Главная",
        },
        inspector: {
            title: "Главная",
            links: {
                claims: "Обращения",
                claims2: "Обращения второй линии",
                clientInfo: "Гости",
            },
        },
        manager: {
            title: "Ваши сотрудники",
            addUser: "Добавить сотрудника",
            learningManagement: "Обучение",
            warningIsLoading: "Загрузка...",
        },
        terDir: {
            title: "Ваши кофейни",
            checkerTitle: "Введите основную информацию о пользователе",
            addUser: "Добавить сотрудника",
            learningManagement: "Обучение",
            errors: {
                loadData: "Ошибка при получении данных от сервера",
            },
        },
        tradePointWarnings: {
            title: "%p#employees# имеют проблемы",
        },
        usersList: {
            lastVisitDate: "Последний вход",
        },
    },
    ingredientsRegistry: {
        common: {
            add: "Добавить",
            cancel: "Отменить",
            description: "Описание",
            delete: "Удалить",
            ok: "Ok",
            save: "Сохранить",
            edit: "Редактировать",
            text: "Текст",
            name: "Наименование",
            print: "Печать",
            notSelected: "Не выбрано",
            unit: "ед. изм.",
            noData: "Данные отсутствуют",
            count: "Количество",
            filter: {
                chooseAll: "Выбрать все",
            },
        },
        catalogs: {
            titlePlural: "Справочники",
            ingredientCategories: {
                titlePlural: "Категории продуктов",
            },
            temperatureRegime: {
                titlePlural: "Температурные режимы",
                add: "Добавить температурный режим",
                edit: "Изменить температурный режим",
            },
            storageCondition: {
                titlePlural: "Условия хранения",
                add: "Добавить условие хранения",
                edit: "Изменить условие хранения",
            },
            states: {
                titlePlural: "Состояния продукта",
                add: "Добавить состояние",
                edit: "Изменить состояние",
            },
            expirationUnit: {
                titlePlural: "Единицы измерений времени",
                add: "Добавить единицу измерения времени",
                edit: "Изменить единицу измерения времени",
            },
            amountUnit: {
                titlePlural: "Физические единицы измерения",
                add: "Добавить физическую единицу измерения",
                edit: "Изменить физическую единицу измерения",
            },
        },
        reports: {
            titlePlural: "Отчеты",
            forRegion: "Реестр продуктов по региону",
            uniqueProducerProducts: "Реестр продуктов",
        },
        category: {
            title: "Категория",
            titlePlural: "Категории",
            editing: "Редактирование категорий продуктов",
            edit: "Редактировать категории",
            add: "Создать категорию",
            name: "Наименование категории",
        },
        errors: {
            httpGetError: "Не удалось загрузить данные с сервера!",
            httpSaveError: "Не удалось сохранить данные!",
            amountBoundError: "Верхняя граница значений меньше нижней! Продукт - ",
        },
        producer: {
            add: "Добавить производителя",
            title: "Производитель",
            titlePlural: "Производители",
            delete: "Удалить производителя",
            deleteConfirm: "Вы действительно хотите удалить производителя?",
            edit: "Редактировать производителя",
            name: "Наименование производителя",
            ingredient: {
                add: "Добавить нового производителя",
                delete: "Удалить продукт производителя",
                deleteConfirm: "Вы действительно хотите удалить продукт производителя?",
                noData: "Информация о производителях отсутствует",
            },
        },
        provider: {
            add: "Добавить поставщика",
            edit: "Редактировать поставщика",
            title: "Поставщик",
            titlePlural: "Поставщики",
            filter: "Фильтр по поставщику",
        },
        region: {
            title: "Регион",
            titlePlural: "Регионы",
            edit: "Редактировать регион",
            add: "Добавить регион",
            filter: "Фильтр по региону",
        },
        title: "Реестр продуктов",
        markings: {
            markingsTitle: "Печать маркировок",
            state: "Состояние",
        },
        ingredient: {
            add: "Добавить продукт",
            amount: {
                title: "Фасовка",
                min: "от",
                max: "до",
            },
            state: {
                title: "Состояние",
            },
            category: "Категория",
            edit: "Редактировать продукт",
            expiration: "Срок годности",
            image: "Изображение",
            isPrimary: {
                title: "Осн./Альт.",
                primary: "Основной",
                alternate: "Альтернативный",
            },
            title: "Продукт",
            titlePlural: "Продукты",
            temperatureRegime: "Температурный режим",
            typeName: "Тип",
            storageConditions: {
                title: "Требования к хранению",
                title2: "Условия хранения и сроки годности",
            },
            type: {
                title: "Тип",
                raw: "Сырье",
                semiFinished: "Полуфабрикат",
            },
            marking: {
                isMarkingPrintable: "Печатать маркировку",
                printMarking: "Маркировка",
                notMarking: "Нет маркировки",
                createdAt: "изготовлено:",
                sellBy: "годен до:",
            },
        },
        success: {
            dataSaved: "Данные сохранены",
        },
    },
    journals: {
        config: "Управление журналами",
        assign: {
            title: "Назначение журналов",
            startDate: "Дата ",
            success: {
                assign: "Назначено: %p#journals#",
                assign0: "Не удалось назначить новые журналы. Такие уже есть",
            },
            errors: {
                selectJournals: "Укажите шаблоны журналов",
                assign: "Не удалось назначить журналы кофейням!",
            },
        },
        available: {
            title: "Мои журналы",
            titleCompleted: "Мои заполненные журналы",
            titleUsersCompleted: "Заполненные журналы сотрудников",
            titleEquipmentsUsersCompleted: "Заполненные журналы оборудования",
            type: {
                users: "Журнал сотрудников",
                equipments: "Журнал оборудования",
            },
            fields: {
                startDate: "Дата начала",
                updatedDate: "Дата заполнения",
                endDate: "Дата окончания",
                status: "Состояние журнала",
                type: "Тип журнала",
            },
            confirm: {
                text: 'Приступить к заполнению журнала "%s" для кофейни "%s"?',
                okText: "Да, приступить",
            },
        },
        users: {
            template: {
                title: "Шаблоны журналов сотрудников",
                titleShort: "Шаблоны",
                editTitle: "Редактирование шаблона",
                addTitle: "Новый шаблон",
                tabs: {
                    main: "Основное",
                    questions: "Вопросы",
                    tradePoints: "Кофейни",
                },
                confirmDelete: {
                    title: "Удалить шаблон журнала",
                    text: 'Вы уверены, что хотите удалить шаблон журнала "%s"?',
                    okText: "Да, удалить",
                },
                success: {
                    edit: "Шаблон изменен",
                    add: "Создан новый шаблон",
                    remove: "Шаблон удален",
                    saveQuestion: "Вопрос изменен",
                    removeQuestion: "Вопрос удален",
                    orderingQuestions: "Порядок вопросов изменен",
                    addTradePoints: "Кофейни добавлены: %s штук",
                    removeTradePoints: "Кофейни удалены",
                },
                errors: {
                    save: "Не удалось изменить шаблон!",
                    getRecord: "Не удалось получить шаблон!",
                    remove: "Не удалось удалить шаблон!",
                    saveQuestion: "Не удалось изменить вопрос!",
                    removeQuestion: "Не удалось удалить вопрос!",
                    orderingQuestions: "Не удалось изменить порядок вопросов!",
                    addTradePoints: "Не удалось добавить кофейни!",
                    removeTradePoints: "Не удалось удалить кофейню!",
                    notificationSecondsDuration: '"Напомнить за" должен быть меньше чем "Повторять 1 раз в"!',
                },
                question: {
                    addTitle: "Новый вопрос",
                    editTitle: "Изменить вопрос",
                    title: "Вопросы",
                    saveSort: "Сохранить порядок расположения",
                    remove: {
                        title: "Удалить вопрос",
                        text: "Вы хотите удалить вопрос %s?",
                    },
                },
            },
            assignment: {
                title: "Заполнение журнала",
                isCompleted: "Заполнен",
                isNotCompleted: "Не заполнен",
                endJournal: {
                    userData: "Заполнено %s из %s сотрудников",
                    title: "Закончить заполнение журнала",
                    text: "Вы уверены, что хотите закончить заполнение журнала?",
                    text2: "У вас не заполнена информация по %s сотрудникам из %s",
                    okText: "Да, закончить",
                },
                confirm: {
                    title: "Заполнить журнал",
                    text: 'Начать заполнение журнала "%s" для сотрудника %s?',
                    okText: "Начать",
                },

                success: {
                    addUserAnswers: "Данные по сотруднику: %s внесены",
                    addEquipmentAnswers: "Данные по оборудованию: %s внесены",
                    setCompleted: "Заполнение журнала завершено",
                },
                errors: {
                    getRecord: "Не удалось получить информацию о журнале!",
                    addUserAnswers: "Не удалось добавить запись по сотруднику!",
                    addEquipmentAnswers: "Не удалось добавить запись по оборудованию!",
                    setCompleted: "Не удалось завершить заполнение журнала!",
                    userIsCompleted: "Вы уже заполнили журнал по этому сотруднику",
                },
                userSelector: {
                    title: "Новый сотрудник",
                    placeholder: "Укажите сотрудника не из кофейни",
                },
            },
            completed: {
                title: "Заполненные журналы сотрудников",
                titleShort: "Заполненные журналы",
                errors: {
                    getCompleted: "Не удалось получить данные журнала!",
                },
            },
        },
        equipments: {
            template: {
                title: "Шаблоны журналов оборудования",
            },
            assignment: {
                completedCount: "Заполнено",
                selectEquipment: "Выбери модель оборудования из предложенного списка",
                endJournal: {
                    countData: "Заполнено оборудования - %s",
                    title: "Закончить заполнение журнала",
                    text: "Вы уверены, что хотите закончить заполнение журнала?",
                    okText: "Да, закончить",
                },
                confirm: {
                    title: "Заполнить журнал",
                    text: 'Начать заполнение журнала "%s" для оборудования %s?',
                    text2: 'Заполнить журнала "%s" для ЕЩЕ ОДНОГО оборудования %s?',
                    okText: "Начать",
                },
            },
            completed: {
                title: "Заполненные журналы оборудования",
                errors: {
                    getCompleted: "Не удалось получить данные журнала!",
                },
            },
        },
    },
    iiko: {
        reporting: {
            users: {
                title: "Пользователи Iiko",
                deleted: "Удален",
                active: "Активен",
                fields: {
                    onlyActive: "Только активные",
                    name: "ФИО",
                    code: "Табельный номер",
                    pinCode: "Пин-код",
                    phone: "Телефон",
                    status: "Статус",
                    id: "ID",
                },
            },
        },
    },
    innerLearning: {
        directories: {
            title: "Справочники по внутреннему обучению",
            coachPayments: {
                title: "Выплаты тренерам",
                list: {
                    name: "Наименование выплаты",
                    sum: "Сумма выплаты",
                },
            },
            coachActivityTypes: {
                title: "Виды мероприятий тренера",
                list: {
                    name: "Наименование мероприятия",
                },
            },
            practicalInternships: {
                title: "Практические стажировки",
                list: {
                    name: "Наименование Стажировки",
                },
            },
            learningActivities: {
                title: "Виды обучающих мероприятий",
                list: {
                    name: "Наименование",
                    place: "Место проведения",
                    learningActivityType: "Тип обучающего мероприятия",
                    price: "Стоимость",
                    employeePay: "Выплата сотруднику",
                },
            },
            videoContentWorks: {
                title: "Работа с видео-контентом",
                list: {
                    name: "Потребность для генерации видео-контента",
                    workType: "Тип работ",
                    price: "Стоимость",
                    employeePay: "Выплата сотруднику",
                },
            },
        },
        list: {
            title: "Внутреннее обучение",
            plansTitle: "Планы внутреннего обучения",
            fields: {
                studentName: "Обучающийся",
                coachName: "Основной наставник",
                dateStart: "Дата начала обучения",
                dateEnd: "Дата окончания обучения",
            },
            deleteConfirm: {
                title: "Удалить запись об обучении",
                text: "Вы уверены что хотите удалить запись об обучении?",
                okText: "Да, удалить",
            },
            errors: {
                add: "Ошибка при попытке добавить запись об обучении!",
                edit: "Ошибка при попытке отредактировать запись об обучении!",
                remove: "Ошибка при попытке удалить запись об обучении!",
                get: "Ошибка при попытке получить данные о записях об обучении!",
            },
            success: {
                add: "Запись об обучении добавлена",
                edit: "Запись об обучении отредактирована",
                remove: "Запись об обучении удалена",
            },
        },
        add: {
            title: "Добавить план обучения сотрудника",
            success: "Данные успешно добавлены",
            error: "Не удалось сохранить данные",
            studentName: "Обучающийся",
            studentRole: "Роль обучающегося",
            studentPhone: "Телефон обучающегося",
            coachName: "Основной наставник",
            coachRole: "Роль основного наставника",
            coachPhone: "Телефон основного наставника",
            dateStart: "Дата начала обучения",
            dateEnd: "Дата окончания обучения",
            groups: {
                dates: "Даты обучения",
                general: "Общая информация",
            },
        },
        plan: {
            assignedEvents: "Назначенные события",
            title: "План сотрудника",
            notAccess: "Нет разрешения",
            excel: "Скачать план обучения",
            userProfile: {
                fullName: "Ф.И.О",
                phone: "Телефон",
                workPlace: "Место работы",
                startDate: "Начало обучения",
                endDate: "Окончание обучения",
                coach: "Основной наставник",
                downloadCost: "Скачать стоимость обучения ↓",
                downloadPlan: "Скачать план обучения ↓",
                post: "Роль",
            },
            trainingProgram: {
                lessons: "Назначенные уроки",
                consultations: "Назначенные консультации и тренинги",
                internships: "Практические стажировки",
            },
            lessons: {
                name: "Название",
                dateEnd: "Срок до",
                date: "Дата и время проведения",
                mark: "Отметка о выполнении",
                status: "Статус",
            },
            activities: {
                cancelReason: "Причина отмены",
                cancelReasonRequired: "Укажите причину отмены",
                name: "Наименование консультации/тренинга",
                date: "Дата и время проведения",
                student: "Обучающийся",
                coach: "Ведущий",
                phone: "Контакты",
                place: "Место проведения",
                address: "Адрес",
                price: "Стоимость",
                employeePay: "Выплата",
                status: "Отметка о выполнении",
                confirm: "Подтверждение",
            },
            attestations: {
                attempt: "Попытка",
                title: "Аттестация",
                grant: "Стипендия",
                addAttempt: "Добавить попытку",
                addGrant: "Назначить стипендию",
                changeStatus: "Изменить статус попытки",
                passedStatus: {
                    complete: "Пройдена",
                    nonComplete: "Не пройдена",
                },
            },
            changeStatus: "Сменить статус",
            totalCosts: {
                activities: "Итоговые суммы по консультациям и тренингам:",
                internships: "Итоговые суммы по стажировкам:",
                priceActivities: "Итоговая стоимость за консультации и тренинги: ",
                priceInternships: "Итоговая стоимость за практические стажировки: ",
            },
        },
        addActivities: {
            title: "Добавить тренинг/консультацию",
            internshipTitle: "Добавить практическую стажировку",
            studentName: "Обучающийся",
            coachName: "Наставник курса",
            coachRole: "Роль наставника курса",
            coachPhone: "Телефона наставника курса",
            dateStart: "Дата начала обучения",
            dateEnd: "Дата окончания обучения",
            learningActivity: "Тренинг/консультация",
            practicalInternship: "Практическая стажировка",
            place: "Место проведения",
            address: "Адрес",
            success: "Запись успешно добавлена",
        },
        addAttestation: {
            date: "Дата и время проведения аттестации",
        },
        trainerTrip: {
            title: "Учет выезда тренера",
            fields: {
                employeeName: "ФИО сотрудника",
                eventType: "Тип мероприятия",
                coffeeShop: "Кофейня",
                startDate: "Дата начала",
                endDate: "Дата окончания",
                dailySalary: "Ежедневный оклад",
                workDaysCount: "Количество рабочих дней",
                additionalPayments: "Дополнительные выплаты",
                paymentType: "Тип выплаты",
                amount: "Сумма выплаты",
            },
            actions: {
                save: "Сохранить",
                addPayment: "Добавить выплату",
            },
            success: {
                save: "Данные успешно сохранены",
            },
            errors: {
                save: "Ошибка при сохранении данных",
            },
        },
        coachTitle: "Наставничество",
    },
    learning: {
        examination: {
            templates: {
                title: "Аттестационные шаблоны",
                fields: {
                    title: "Наименование",
                    updatedDate: "Изменено",
                    gradeId: "Грейд",
                },
                deleteConfirm: {
                    title: "Удалить шаблон",
                    text: "Вы точно хотите удалить %p#templates#?",
                    okText: "Да, удалить",
                },
                success: {
                    deleteTemplates: "Удалено: %p#templates#",
                },
                errors: {
                    loadData: "Не удалось получить список шаблонов!",
                    deleteTemplates: "Не удалось удалить шаблон!",
                },
            },
            template: {
                title: "Редактирование шаблона",
                addTitle: "Новый шаблон",
                fields: {
                    title: "Наименование",
                    timeLimit: "Ограничение по времени (минут)",
                    passRate: "Процент прохождения",
                    isAutomaticReassignment: "Автоматически переназначать",
                    autoReassignmentInterval: "Интервал переназначений (в днях)",
                    autoReassignmentPeriod: "Период переназначений (в днях)",
                    gradeId: "Грейд",
                },
                errors: {
                    loadData: "Не удалось получить шаблон!",
                    addTemplate: "Не удалось создать шаблон!",
                    updateTemplate: "Не удалось изменить шаблон!",
                },
                success: {
                    addTemplate: "Шаблон создан",
                    updateTemplate: "Шаблон изменен",
                },
                rules: {
                    title: "Уроки",
                    addTitle: "Новый урок",
                    lesson: "Урок",
                    questionsAmount: "Кол-во вопросов (макс. %s)",
                    deleteConfirm: {
                        title: "Удалить урок",
                        text: 'Вы уверены, что хотите удалить урок "%s"',
                        okText: "Да, удалить",
                    },
                    errors: {
                        addData: "Не удалось получить информацию об уроке",
                        lessonStepsCount: "У данного урока нет шагов с вопросами",
                        lessonAlreadyExist: "Данный урок уже есть в этой аттестации",
                    },
                },
            },
            assign: {
                title: "Назначение аттестации",
                fields: {
                    users: "Сотрудники",
                    expirationDate: "Дедлайн",
                    examination: "Аттестация",
                },
                success: {
                    assign: "Назначено: %p#examinations# ",
                },
                errors: {
                    assign: "Не удалось назначить аттестацию!",
                },
            },
            results: {
                title: "Аттестации",
                statusList: {
                    all: "Все",
                    success: "Завершенные",
                    failed: "Проваленные",
                    terminated: "Отмененные",
                    notEnded: "Не законченные",
                },
                fields: {
                    title: "Аттестация",
                    createdDate: "Назначена",
                    user: "Аттестуемый",
                    tradePoint: "Кофейня",
                    rightAnswerPercent: "Пройдено",
                    status: "Статус",
                    grade: "Грейд",
                },
                result: {
                    title: "Аттестация",
                    mainData: {
                        status: "Статус",
                        user: "Аттестуемый",
                        createdDate: "Дата создания",
                        deadline: "Пройти до",
                        sessionStart: "Дата начала",
                        sessionEnd: "Дата окончания",
                        passRate: "Для прохождения",
                        passRateValue: "%s% из %p#questions#",
                        result: "Пройдено",
                        resultValue: "%s%, %p#errors#",
                    },
                    errors: {
                        loadData: "Не удалось получить данные об аттестации!",
                    },
                    details: {
                        title: "Прохождение",
                        noData: "Нет данных",
                    },
                },
            },
            learning: {
                title: "Прохождение аттестации",
                start: "Начать",
                continue: "Продолжить",
                errors: {
                    checkSession: "Не удалось получить информацию об аттестации!",
                    startSession: "Не удалось приступить к аттестации!",
                    terminated: "Прохождение урока было отменено",
                    stepOut: "Не удалось отправить данные о прохождении шага аттестации!",
                },
                messages: {
                    continue: 'Вы хотите продолжить прохождение аттестации "%s?"',
                    start: 'Вы хотите начать прохождение аттестации "%s?"',
                    passed: "Вы уже прошли эту аттестацию",
                },
                completeMessage: {
                    completed: {
                        title: "Поздравляем!",
                        text: "Вы успешно прошли аттестацию.",
                    },
                    expired: {
                        title: "Вы не успели",
                        text: "К сожалению вы не успели вовремя",
                    },
                    failed: {
                        title: "Аттестация провалена",
                        text: "Слишком много ошибок.",
                    },
                    terminated: {
                        title: "Аттестация отменен",
                        text: "Прохождение аттестации было отменено.",
                    },
                },
            },
        },
        grades: {
            title: "Дерево грейдов",
            gradeTree: "Дерево грейдов",
            adminUserSelect: "Пользователь",
            toEdit: "Редактировать грейды",
            errors: {
                svg: "Не удалось загрузить дерево грейдов",

                lessonLearnSessionId: "Отсутствует идентификатор сессии, обратитесь к администратору",
            },

            stepInfo: {
                title: "Информация по прохождению шага грейда",
                addFailedGradeStep: "Провалить",
                lesson: {
                    title: "Урок %s ( ID: %s )",
                    status: "Статус",
                    isAvailable: "Доступность",
                    createdDate: "Дата создания",
                },
                gradeStepSession: {
                    title: "Сессия шага грейда ( ID: %s )",
                    createdDate: "Дата создания",
                    status: "Статус",
                    initiator: "Инициатор",
                    sessionBegin: "Начало",
                    sessionEnd: "Окончание",
                    expires: "Нужно окончить до",
                },
                lessonLearnSession: {
                    title: "Сессия урока ( ID: %s )",
                    createdDate: "Дата создания",
                    status: "Статус",
                    isAssigned: "Назначен",
                    errors: "Ошибки",
                    countLessonLearnSession: "Кол-во вхождений в GradeSession",
                    expirationDate: "Нужно окончить до",
                },
                errors: {
                    addFailedGradeStepSession: "Не удалось провалить шаг",
                },
            },
            adminInfo: {
                fields: {
                    createdDate: "Дата",
                    type: "Статус",
                    comment: "Описание",
                },
                confirmAdd: {
                    title: "Назначить грейд",
                    text: 'Вы уверены, что хотите назначить грейд "%s" пользователю?',
                    okText: "Да, назначить",
                },
                confirmRemove: {
                    title: "Удалить грейд",
                    text: 'Вы уверены, что хотите удалить грейд "%s" у пользователя?',
                    okText: "Да, удалить",
                },
                isAchieved: "Получен",
                isNotAchieved: "Не получен",
                showDetails: "Подробнее",
                toAdd: "Добавить грейд",
                toRemove: "Удалить грейд",
                toHistory: "История изменений",
                historyTitle: 'История грейда "%s" - текущий статус "%s"',
            },
        },
        gradeStep: {
            title: "%s. Шаг %s",
            errors: {
                loadData: "Не удалось найти информацию по шагу грейда!",
            },
            messages: {
                continue: 'Вы хотите продолжить прохождение шага грейда "%s?"',
                start: 'Вы хотите начать прохождение шага грейда "%s?"',
                failed: "Слишком много ошибок. Урок провален.",
                passed: "Вы уже прошли этот урок",
            },
            start: "Начать",
            continue: "Продолжить",
        },
        managerWithoutGrade: {
            title: "Менеджеры без грейда",
            fields: {
                username: "ФИО",
                tradePoint: "Кофейня",
            },
            filters: {
                gradeTreeId: "Дерево грейдов",
                date: "Дата",
            },
            errors: {
                getManagersWithoutGrade: "Не удалось получить список менеджеров",
            },
            confirm: {
                title: "Информация о пользователе",
                text: "Перейти в настройки пользователя %s ?",
                okText: "Да, перейти",
            },
        },
        gradesList: {
            title: "Редактирование грейдов",
            gradeTree: "Дерево грейдов",
            fields: {
                id: "ID",
                image: "Фото",
                name: "Название",
            },
            errors: {
                getGradeListByTree: "Ошибка при получении информации о грейдах ",
            },
        },
        gradeEdit: {
            title: "Редактирование грейда",
            addGradeStep: "Добавить шаг грейда",
            gradeSteps: "Шаги грейда",
            fields: {
                name: "Наименование",
                finishFormUrl: "Ссылка на форму опроса",
                imageId: "Изображение",
                userRoles: "Роли пользователей",
            },
            confirm: {
                title: "Удалить шаг грейда",
                text: 'Вы точно хотите удалить шаг "%s"?',
                okText: "Да, удалить",
            },
            addStep: {
                title: "Добавить шаг",
                fields: {
                    lesson: "Урок",
                    deadline: "Дата прохождения",
                },
            },
            messages: {
                reorder: "Порядок шагов грейда был изменен",
                remove: "Шаг удален",
                add: "Шаг добавлен",
                save: 'Грейд "%s" сохранен',
            },
            errors: {
                reorder: "Ошибка при попытке изменения порядка шагов грейда",
                remove: "Ошибка при попытке удаления шага",
                add: "Ошибка при попытке добавления шага",
                save: "Ошибка при попытке сохранения грейда",
                notFoundSteps: "Найдены зацикленные шаги: %s. Обратитесь к администратору",
                stepsCount:
                    "Кол-во шагов грейда не соответствует ожидаемому %s из %s. Обратитесь к администратору",
                getGrade: "Ошибка при получении информации о грейде",
            },
        },
        gradeLearning: {
            title: "Дерево грейдов",
            noGrades: "Грейды отсутствуют",
            toAction: "Перейти",
            toUserGrade: "Посмотреть",
            toGrades: "Дерево грейдов",
            toAdminGrades: "Подробнее",
            toInformationLessonText: "Хотите сейчас повторить материал в справочных уроках?",
            toInformationLessonButton: "Да, повторю сейчас",
            toInformationLessonCancel: "Нет, повторю позже",
            toGoConfirm: {
                new: {
                    text: 'Начать прохождение урока "%s"?',
                    okText: "Да, начать",
                },
                inProgress: {
                    text: 'Продолжить прохождение урока "%s"?',
                    okText: "Да, продолжить",
                },
                examinationCreated: {
                    text: 'Начать прохождение аттестации "%s"?',
                    okText: "Да, начать",
                },
                examinationInProgress: {
                    text: 'Продолжить прохождение аттестации "%s"?',
                    okText: "Да, продолжить",
                },
            },
            gradeStatus: {
                ok: "Получен",
                problems: "Нужно пройти",
                blocked: "Заблокирован",
                warnings: "Скоро будет аннулирован",
            },
            success: {
                actionGradePassed: 'Грейд "%s" пройден',
                actionGradeFailed: 'Грейд "%s" удален',
                actionStepPassed: 'Шаг "%s" пройден',
                actionStepFailed: 'Шаг "%s" провален',
                actionStepConfirmed: 'Шаг "%s" подтвержден',
                actionStepReseted: 'Шаг "%s" аннулирован',
                changeExaminationStatus: 'Статус аттестации "%s" изменен',
            },
            errors: {
                getUserGradeSteps: "Не удалось получить информацию о шагах грейда!",
                passed: 'Вы уже прошли урок "%s".',

                waitingConfirmEmployee: 'Урок "%s" ожидает подтверждения от менеджера',
                waitingConfirm: 'Урок "%s" ожидает подтверждения от академии',
                failed: {
                    title: "Урок провален",
                    textEmployee: "Слишком много ошибок. Обратитесь к менеджеру.",
                    text: "Слишком много ошибок. Обратитесь к сотруднику академии.",
                },
                expired: {
                    title: "Урок провален",
                    textEmployee: "Вы не успели вовремя. Обратитесь к менеджеру.",
                    text: "Вы не успели вовремя. Обратитесь к сотруднику академии.",
                },
                notAvailable: 'Данный урок "%s" вам пока не доступен.',
                setStepAction: "Не удалось выполнить действие над шагом грейда!",
                userGrade: "Не удалось выполнить действие над грейдом пользователя!",
                changeExaminationStatus: "Не удалось изменить статус аттестации",
                examinationPassed: 'Вы уже прошли аттестацию "%s"!',
                examinationFailed: "Слишком много ошибок. Обратитесь к сотруднику академии.",
                examinationExpired: "Вы не успели вовремя. Обратитесь к сотруднику академии.",
            },
        },
        usersGrades: {
            title: "Грейды сотрудников",
            fields: {
                username: "ФИО",
                tradePoint: "Кофейня",
                grades: "Грейды",
            },
            filters: {
                gradeTreeId: "Дерево грейдов",
                tradePointId: "Кофейня",
                userIds: "Пользователи",
                date: "Дата",
            },
            errors: {
                insertData: "Укажите Кофейню или пользователя!",
                getUserGradesInfo: "Ошибка при получении данных о грейдах!",
            },
            confirm: {
                title: "Информация о пользователе",
                text: "Перейти в настройки пользователя %s ?",
                okText: "Да, перейти",
            },
        },
        lessons: {
            assign: {
                title: "Назначение уроков",
                noRecords: "Ничего не найдено",
                fields: {
                    lessons: "Уроки",
                    categories: "Категории",
                    expirationDate: "Дедлайн",
                    users: "Сотрудники",
                },
                errors: {
                    addIds: "Укажите уроки и/или категории уроков",
                    add: "Ошибка при назначении урока!",
                    loadData: "Не удалось получить информацию об уроках!",
                },
                success: {
                    add: "Назначено: %p#lessons#",
                },
            },
            info: {
                toPrevStep: "Предыдущий шаг",
                toNextStep: "Далее",
                toList: "К списку уроков",
                errors: {
                    loadData: "Не удалось получить информацию об уроке!",
                    noSteps: "В уроке пока нет информации",
                },
            },
            infoList: {
                title: "Справочные уроки",
                noRecords: "Ничего не найдено",
                errors: {
                    loadData: "Не удалось получить информацию об уроках!",
                },
            },
            catalog: {
                title: "Каталог уроков",
                noRecords: "Ничего не найдено",
                selectGrade: "Укажите грейд",
                addButton: "Добавить урок/каталог",
                grade: "Грейд",
                edit: {
                    title: {
                        addLesson: "Добавить урок",
                        addCatalog: "Добавить каталог",
                        editLesson: "Редактировать урок",
                        editCatalog: "Редактировать каталог %s",
                    },
                    lesson: "Урок",
                    catalog: "Каталог",
                    fields: {
                        type: "Тип",
                        lessonParentCatalogId: "Родительский каталог",
                        name: "Название",
                        image: "Фото",
                        isInfoShow: "Выводить в справочных уроках",
                    },
                    success: {
                        catalogUpdated: "Каталог %s отредактирован",
                        catalogAdded: "Каталог %s добавлен",
                    },
                    errors: {
                        updateCatalog: "Не удалось отредактировать каталог!",
                    },
                },
                deleteConfirm: {
                    lessonTitle: "Удалить урок",
                    lessonText: 'Вы уверены, что хотите удалить урок "%s"?',
                    catalogTitle: "Удалить каталог",
                    catalogText: 'Вы уверены, что хотите удалить каталог "%s"?',
                    okText: "Да, удалить",
                    success: {
                        removeLesson: 'Урок "%s" удален',
                        removeCatalog: 'Каталог "%s" удален',
                    },
                    errors: {
                        removeLesson: "Не удалось удалить урок!",
                        removeCatalog: "Не удалось удалить каталог!",
                    },
                },
                tooltips: {
                    catalogDelete: "Удалить каталог",
                    catalogEdit: "Редактировать каталог",
                    catalogLessonPaste: 'Вставить урок "%s" в категорию',
                    lessonCopy: "Скопировать урок",
                    lessonEdit: "Редактировать урок",
                    lessonDelete: "Удалить урок",
                    preview: "Посмотреть",
                },
                success: {
                    lessonCopy: 'Урок "%s" скопирован в память',
                    lessonCopied: 'Урок "%s" скопирован в каталог',
                },
                errors: {
                    loadData: "Не удалось получить информацию об уроках!",
                    lessonCopied: "Не удалось скопировать урок!",
                },
                gradeFilter: {
                    title: "Грейды",
                },
            },
            lesson: {
                lesson: "Урок",
                grades: {
                    title: "Грейды",
                    addTitle: "Добавить урок в грейд",
                    deleteText: 'Вы уверены, что хотите удалить урок из грейда "%s"?',
                    success: {
                        stepRemove: "Урок удален из грейда!",
                    },
                    errors: {
                        stepRemove: "Не удалось удалить урок из грейда!",
                    },
                    fields: {
                        gradeId: "Грейд",
                        deadline: "Дата прохождения",
                    },
                },
                lessonSteps: "Шаги урока",
                fields: {
                    lessonCatalogId: "Родительский каталог",
                    name: "Название",
                    image: "Фото",
                    description: "Описание",
                    isInformationLesson: "Урок справочный",
                    confirmType: "Подтверждение",
                    isRandomLesson: "Шаги предлагать в случайном порядке",
                    randomCount: "Шагов для прохождения",
                    isExamLesson: "Урок экзаменационный (выход из урока будет засчитан, как провал)",
                    isReady: "Урок готов",
                    canBeAssignedByManager: "Может быть назначен менеджером",
                    maxErrors: "Максимальное число ошибок в уроке",
                    expiration: "Времени на прохождение",
                    isImportantChanges: "Важные изменения",
                    endDate: "Дата к которой нужно пройти урок в грейде",
                },
                errors: {
                    getCatalogLesson: "Не удалось получить данные урока!",
                    randomCount: "Укажите сколько нужно шагов для прохождения",
                    endDate: "Укажите дату к которой нужно пройти урок в грейде",
                    endDateMin: "Дата к которой нужно пройти не может быть меньше текущей",
                    endDateMax: "Дата к которой нужно пройти не может быть больше 6-ти месяцев",
                    maxErrors: "Укажите максимальное число ошибок в уроке",
                    updateLesson: "Не удалось отредактировать урок!",
                    updateStep: "Не удалось отредактировать шаг урока!",
                    removeStep: "Не удалось удалить шаг урока!",
                    sortSteps: "Не удалось изменить порядок шагов!",
                    removeStepAnswer: "Не удалось удалить ответ шага урока!",
                    sortAnswers: "Не удалось изменить порядок ответов!",
                    copyStep: "Не удалось скопировать шаг урока!",
                    cutStep: "Не удалось переместить шаг урока!",
                },
                success: {
                    sortAnswers: "Порядок ответов изменен",
                    sortSteps: "Порядок шагов урока изменен",
                    copyStep: "Шаг урока скопирован",
                    cutStep: "Шаг урока перемещен",
                },
                steps: {
                    actions: {
                        add: "Добавить шаг",
                        copy: 'Копировать шаг "%s в данный урок"',
                        cut: 'Переместить шаг "%s"  в данный урок',
                        dragEnabled: "Сортировка разрешена",
                        dragDisabled: "Сортировка запрещена",
                    },
                    confirmDelete: {
                        title: "Удалить шаг урока",
                        text: 'Вы действительно хотите удалить шаг урока "%s"?',
                        okText: "Да, удалить",
                    },
                    fields: {
                        lessonBody: "Описание",
                        shortDescription: "Название",
                        stepType: "Тип шага",
                        isRandomStep: "Ответы в случайном порядке",
                    },
                    answers: {
                        title: "Варианты ответов",
                        editTitle: "Вариант ответа",
                        add: "Добавить ответ",
                        fields: {
                            answerBody: "Ответ",
                            isWrong: "Это не верный ответ",
                        },
                    },
                    editStep: "Редактировать шаг урока",
                    addStep: "Редактировать шаг урока",
                    copy: "Скопировать шаг",
                    copied: 'Шаг "%s" скопирован',
                    stepErrors: {
                        noAnswers: "Нет вариантов ответов",
                        onlyOneRightAnswer: "Должен быть только один правильный вариант",
                        noRightAnswers: "Укажите правильный вариант ответа",
                        typeIsDeprecated: "Данный тип шага не используется, измените тип",
                    },
                },
                answers: {
                    confirmDelete: {
                        title: "Удалить вариант ответа",
                        text: "Вы действительно хотите удалить этот вариант ответа?",
                        okText: "Да, удалить",
                    },
                },
                pollTemplateList: {
                    title: "Опросы",
                },
            },
        },
        lessonLearnSession: {
            title: "Урок",
            start: "Начать",
            continue: "Продолжить",
            errors: {
                getLesson: "Не удалось получить информацию об уроке!",
                startSession: "Не удалось начать урок!",
                completed: "Вы уже прошли этот урок",
                failed: "Вы провалили прохождение урока. Вы допустили %s ошибок",
                expired: "Вы не успели пройти урок за выделенное время.",
                terminated: "Прохождение урока было отменено",
                waitingConfirm: "Урок ожидает подтверждения",
                noStep: "Не найден шаг урока. Обратитесь в Академию",
                stepOut: "Не удалось отправить данные о прохождении шага урока",
                addNoInstance: "Не удалось отправить данные об опросе!",
            },
            messages: {
                continue: 'Вы хотите продолжить прохождение урока "%s?"',
                start: 'Вы хотите начать прохождение урока "%s?"',
                failed: "Слишком много ошибок. Урок провален.",
                passed: "Вы уже прошли этот урок",
                expired: "Вы не успели пройти урок за выделенное время. ",
            },
        },
        management: {
            title: "Управление обучением",
            waitingConfirmTitle: "Подтверждение уроков",
            failedTitle: "Проваленные уроки",
            expiredTitle: "Просроченные уроки",
            startDate: "Начиная с",
            fields: {
                user: "Пользователь",
                lesson: "Урок",
                grade: "Грейд",
                tradePoint: "Кофейня",
                createdDate: "Дата назначения",
                sessionBegin: "Дата начала",
                sessionEnd: "Дата окончания",
                expirationDate: "Пройти до",
            },
            waitingConfirm: {
                title: "Подтверждение прохождения",
                text: 'Вы уверены, что хотите подтвердить прохождение урока "%s" пользователю %s?',
                okText: "Да, подтвердить",
            },
            failedConfirm: {
                title: "Сбросить прохождение",
                text: 'Сбросить провал урока "%s" пользователю %s?',
                okText: "Да, сбросить",
            },
            success: {
                confirmLessonStep: 'Урок "%s" подтвержден',
                resetLessonStep: 'Урок "%s" сброшен',
            },
            errors: {
                loadData: "Не удалось получить информацию об обучении!",
                confirmLessonStep: "Не удалось подтвердить прохождение урока!",
                resetLessonStep: "Не удалось подтвердить прохождение урока!",
            },
            failedLessonInfo: {
                userAnswer: "Ответ пользователя",
                rightAnswer: "Правильный ответ",
                rightAnswers: "Правильные ответы",
                wrongAnswers: "Неправильные ответы",
                collapse: "Свернуть",
                expand: "Развернуть",
            },
        },
        reports: {
            title: "Генерация отчетов",
            fields: {
                dateRage: "Период",
                type: "Тип отчета",
                startDate: "Начальная дата",
                endDate: "Конечная дата",
                lessonIds: "Уроки",
                gradeStepIds: "Шаги грейдов",
                examinationIds: "Аттестации",
                tradePointIds: "Кофейни",
                pollTemplateUid: "Опрос",
                gradeTree: "Дерево грейдов",
            },
            errors: {
                init: "Ошибка при получении данных от сервера!",
                submit: "Ошибка при получении отчета",
            },

            submitBtnText: "Скачать отчет",
        },
        userErrorFeedbacks: {
            title: "Найденные пользователями ошибки",
            fields: {
                user: "Инициатор",
                lesson: "Урок",
            },
            noRowsLabel: "Ошибок не найдено",
            errors: {
                loadData: "Не удалось получить список ошибок!",
            },
            edit: {
                title: "Обработка ошибки",
                toCancel: "Отказать",
                toComplete: "Выполнено",
                toActive: "Вернуть в обработку",
                fields: {
                    lesson: "Урок",
                    step: "Шаг урока",
                    createdDate: "Дата",
                    user: "Инициатор",
                    description: "Описание",
                    selectedText: "Выделенный текст",
                },
                tooltips: {
                    view: "Посмотреть урок",
                    edit: "Редактировать шаг урока",
                    toUser: "Посмотреть пользователя",
                },
                success: {
                    setStatus: "Статус изменен на %s",
                },
                errors: {
                    loadData: "Не удалось получить информацию об ошибке!",
                    setStatus: "Не удалось изменить статус!",
                },
            },
        },
    },
    legalPerson: {
        title: "Юридические лица",
        addTitle: "Новое юр. лицо",
        fields: {
            title: "Юридическое лицо/ИП",
            inn: "ИНН",
            address: "Адрес юридического лица",
            contacts: "Контакты юридического лица",
            kpp: "КПП",
        },
        errors: {
            getRecord: "Не удалось получить информацию о юр.лице!",
        },
    },
    main: {
        loaderText: "Идет загрузка данных. Подождите...",
    },
    messages: {
        title: "Оповещения",
        toUrl: "Перейти",
        refresh: "Обновить",
        readAll: "Пометить все как прочитанные",
        types: {
            title: "Типы оповещений",
            fields: {
                name: "Системное название",
                isCheckedByDefault: "Выбран по умолчанию",
            },
        },
        status: {
            site: {
                new: "Новое",
                ok: "Прочитано на портале",
            },
            email: {
                failed: "Не удалось отправить e-mail. Проверьте настройки оповещений. Возможно, Вы заблокировали получение сообщений с портала в электронной почте.",
                readed: "Оповещение было отправлено на e-mail",
            },
            telegram: {
                failed: "Не удалось отправить сообщение. Проверьте настройки оповещений. Возможно, Вы заблокировали бота в telegram.",
                readed: "Оповещение было отправлено через Telegram",
            },
        },
        errors: {
            markAllMessageAsRead: "Ошибка. Не удалось пометить все сообщения как прочитанные",
        },
        config: {
            title: "Настройки оповещений",
            email: {
                title: "Оповещения по E-mail",
                email: "Укажите E-mail для оповещения",
                confirm: "Хочу получать оповещения на E-mail",
                messageTypes: "Типы оповещений",
            },
            telegram: {
                title: "Оповещения в Telegram",
                confirm: "Хочу получать оповещения через Telegram",
                messageTypes: "Типы оповещений",
            },
            errors: {
                saveConfig: "Не удалось сохранить настройки!",
            },
            success: {
                saveConfig: "Настройки сохранены",
            },
        },
        sender: {
            title: "Рассылка сообщений",
            reset: "Сброс",
            fields: {
                title: "Заголовок",
                description: "Сообщение",
                messageType: "Тип",
                url: "Ссылка",
                user: "Получатель",
                createdDate: "Дата",
            },
            errors: {
                sendMessage: "Не удалось отправить сообщение!",
            },
            success: {
                sendMessage: "Сообщение отправлено",
            },
        },
    },
    mobileAppManagement: {
        title: "Управление мобильным приложением",
        fields: {
            startDate: "Начальная дата",
            endDate: "Конечная дата",
        },
        types: {
            title: "Тип управления",
            type0: "Переотправка отзывов в диапазоне дат",
        },
        submitBtnText: "Переотправить отзывы",
        reSendingSuccess: "Переотправка отзывов успешно завершена",
        reSendingError: "Не удалось переотправить отзывы",
    },
    navigationMenu: {
        title: "Редактор меню",
        category: {
            editTitle: "Изменить категорию",
            addTitle: "Новая категория",
            sortTooltip: "Сортировка",
            editTooltip: "Редактировать категорию",
            addTooltip: "Создать категорию",
            deleteTooltip: "Удалить категорию",
            fields: {
                title: "Название",
                order: "Порядок вывода",
            },
            deleteConfirm: {
                title: "Удалить категорию",
                text: 'Вы действительно хотите удалить категорию "%s"',
                okText: "Да, удалить",
            },
            success: {
                saveCategory: "Категория отредактирована",
                deleteCategory: "Категория удалена",
                saveOrder: "Порядок изменен",
                saveItem: "Пункт меню изменен",
                removeItem: "Пункт меню удален",
            },
            errors: {
                saveCategory: "Не удалось сохранить категорию!",
                deleteCategory: "Не удалось удалить категорию!",
                saveOrder: "Не удалось изменить порядок!",
                saveItem: "Не удалось изменить пункт меню!",
                removeItem: "Не удалось удалить пункт меню!",
            },
        },
        items: {
            saveSort: "Сохранить порядок расположения",
            fields: {
                displayName: "Видимое название",
                internalName: "Системное имя",
                icon: "Иконка",
                uri: "ссылка",
                categoryId: "Категория",
                roles: "Роли",
                accessFeatureIds: "Доступы",
                accessFeatureCategoryIds: "Категории доступов",
            },
            errors: {
                remove: "Ошибка при удалении пункта меню!",
                save: "Ошибка при сохранении пункта меню!",
                addAccessFeaturesOrRoles: "Укажите роли или доступы",
            },
            deleteConfirm: {
                title: "Удалить пункт меню",
                text: 'Вы хотите удалить пункт меню "%s"?',
                okText: "Да, удалить",
            },
            editTitle: "Редактировать пункт меню",
            addTitle: "Новый пункт меню",
        },
        accessFeatures: "Доступы",
        accessFeatureCategories: "Категории доступов",
        roleFilter: "Роли",

        viewer: {
            title: "Просмотр меню",
            userSelect: "Пользователь",
            view: "Просмотр",
        },
    },
    news: {
        title: "Новости",
        delConfirm: 'Вы действительно хотите удалить новость "%s"?',
        delConfirmYesBtn: "Удалить",
        fromDate: "От",
        startDate: "Скрыто до",
        isDeleted: "Новость удалена",
        errors: {
            notFound: "Новость не найдена!",
            load: "Не удалось получить данные!",
        },
        success: {
            remove: "Новость удалена",
        },
        edit: {
            addTitle: "Добавить новость",
            added: 'Новость "%s" создана',
            editTitle: "Редактирование новости",
            edited: 'Новость "%s" отредактирована',
            name: "Название",
            shortDescription: "Краткое описание",
            description: "Новость",
            image: "Фото",
            publicationDate: "Дата публикации",
        },
        search: {
            placeholder: "Введите текст для поиска",
        },
    },
    notFound: {
        redirectText: "Идет перенаправление. Подождите...",
    },
    okk: {
        preOrderCancels: {
            preOrderCancel: {
                modalTitle: "Добавление отмены предзаказа",
                modalUpdateTitle: "Обновление отмены предзаказа",
                tradePointTitle: "Кофейня",
                entryDateTitle: "Дата внесения",
                cancelReason: "Ситуация(Не забрал/Отменил)",
                cancelReason1: "Не забрал",
                cancelReason2: "Отменил",
                descriptionTitle: "Описание",
                dateStartBlockTitle: "Блокировка с",
                dateEndBlockTitle: "Блокировка до",
                noRecords: "Информация не найдена",
            },
        },
        checkList: {
            checkListTemplates: {
                title: "Шаблоны чек-листов",
                addTitle: "Создать шаблон",
                addCategory: "Создать Категорию",
                editTitle: "Изменить шаблон",
                editItems: "Редактировать шаги",
                main: "Основное",
                errors: {
                    load: "Не удалось получить список чек-листов",
                    noRecords: "Не найдено",
                    deleted: "Не удалось удалить шаблон",
                    templateCategories: "Не удалось получить список категорий!",
                    added: "Не удалось создать шаблон!",
                    updated: "Не удалось изменить шаблон!",
                    getTemplate: "Не удалось получить информацию о шаблоне!",
                    saveCategory: "Не удалось изменить категорию!",
                    deleteCategory: "Не удалось удалить категорию!",
                    copyTemplate: "Не удалось скопировать шаблон!",
                },
                success: {
                    deleted: "Шаблон удален",
                    added: 'Шаблон "%s" создан',
                    updated: 'Шаблон "%s" изменен',
                    saveCategory: "Категория отредактирована",
                    deleteCategory: "Категория удалена",
                },
                deleteConfirm: {
                    title: "Удалить шаблон?",
                    text: 'Вы точно хотите удалить шаблон "%s" ?',
                    categoryTitle: "Удалить категорию?",
                    categoryText: 'Вы точно хотите удалить категорию "%s" ?',
                    okText: "Да, удалить",
                },
                fields: {
                    createdDate: "Создан",
                    updatedDate: "Изменен",
                    title: "Название",
                    qcCheckListTemplateCategory: "Категория",
                    penalizationType: "Начислять баллы за",
                    complaintCreationMode: "Обращения создаются",
                    showPreviousResultsDuringEvaluation: "Показывать результаты предыдущей оценки чек-листа",
                    canBeFilledByManager: "Может быть заполнен менеджером или территориальным директором",
                    canBeFilledByExternalUser: "Может быть заполнен внешним пользователем",
                },
                items: {
                    title: "Шаги",
                    editTitle: "Редактировать шаг",
                    addTitle: "Добавить шаг",
                    insertSearchText: "Укажите текст для поиска. Не менее %s символов",
                    fields: {
                        title: "Наименование",
                        description: "Описание",
                        requirement: "Требование",
                        samplePhoto: "Образец",
                        id: "ID",
                        template: "Шаблон",
                        category: "Категория",
                    },
                    deleteConfirm: {
                        title: "Удалить шаг?",
                        text: 'Вы точно хотите удалить шаг  "%s" ?',
                        okText: "Да, удалить",
                    },
                    deleteConfirmItems: {
                        title: "Удаление шагов",
                        text: "Вы точно хотите удалить %p#steps# ?",
                        okText: "Да, удалить",
                    },
                    success: {
                        updated: "Шаги шаблона изменены",
                        itemUpdated: "Шаг шаблона изменен",
                        itemsDeleted: "%p#steps# удалено",
                    },
                    errors: {
                        updated: "Не удалось изменить шаги шаблона!",
                        itemUpdated: "Не удалось изменить шаг шаблона!",
                        notFound: "Не удалось найти шаги шаблонов!",
                        itemsDeleted: "Не удалось удалить шаги шаблонов!",
                    },
                },
                copy: {
                    title: "Копирование шаблона",
                },
            },
            templateCategory: {
                title: "Редактировать категорию",
                addTitle: "Новая категория",
                fields: {
                    title: "Название",
                },
            },
            checkListItem: {
                tooltips: {
                    retry: "Повторить снимок",
                    capture: "Сделать снимок",
                },
                example: {
                    title: "Пример",
                },
                finish: {
                    success: "Чек-лист отправлен на проверку!",
                    sendForCheck: "Отправить на проверку",
                    stay: "Не хочу",
                    continue: "Хочу",
                    confirmMessage: "Вы действительно хотите отправить чек-лист на проверку?",
                    quizBeforeConfirmMessage: "Пройдите опрос перед отправкой ответов на проверку",
                },
                errors: {
                    save: "Не удалось сохранить данные.",
                },
            },
            checkList: {
                title: "Чек-листы ОУК",
                status: "Статус чек-листа",
                startDate: "Период с",
                endDate: "Период по",
                fields: {
                    createdDate: "Дата создания",
                    title: "Название",
                    tradePointName: "Кофейня",
                    evidenceCollectorName: "Сборщик фото",
                    evidenceCollectionFinished: "Дата заполнения",
                    evaluationFinished: "Дата оценки",
                    evaluatedBy: "Проверяющий",
                    actions: "Действия",
                },
                actions: {
                    photo: "Заполнить чек-лист",
                    delete: "Удалить чек-лист",
                    user: "Назначить проверяющего",
                    check: "Оценить",
                    view: "Посмотреть",
                    deleteConfirm: {
                        title: "Удалить чек-лист",
                        text: 'Вы уверены, что хотите удалить чек-лист "%s"',
                        okText: "Да, удалить",
                    },
                },
                userSelector: {
                    title: "Назначить ответственного",
                    okText: "Назначить",
                    onReset: "Удалить ответственного",
                    user: "Ответственный",
                    fields: {
                        imageId: "Фото",
                        fullName: "ФИО",
                        tradePoints: "Кофейня",
                        role: "Роль",
                        phone: "Телефон",
                    },
                },
                errors: {
                    loadData: "Не удалось получить список чек-листов!",
                    initStartInspector: "Не удалось назначить начального инспектора для чек-листа!",
                    remove: "Не удалось удалить чек-лист!",
                    assignCollector: "Не удалось назначить ответственного на заполнение чек-листа!",
                    resetCollector: "Не удалось удалить ответственного на заполнение чек-листа!",
                },
                success: {
                    remove: "Чек-лист удален",
                    assignCollector: '%s назначен ответственным за заполнение чек-листа "%s" на кофейне "%s"',
                    resetCollector: "Ответственный удален",
                },
                add: {
                    title: "Назначение чек-листов",
                    save: "Назначить",
                    fields: {
                        templates: "Шаблоны чек-листов",
                        tradePoints: "Кофейни",
                    },
                    success: {
                        assign: "Назначено: %p#checkList#",
                    },
                    errors: {
                        templates: "Не удалось получить список шаблонов!",
                        assign: "Не удалось назначить чек-листы!",
                        tradePoints: "Не удалось получить список кофеен!",
                    },
                },
            },
            result: {
                title: "Результат: %s",
                show: "Показать результаты опроса",
                values: {
                    tradePoint: "Кофейня",
                    status: "Статус шага",
                    evidenceCollector: "Сборщик фото",
                    inspector: "Проверяющий",
                    evaluationDate: "Период оценки",
                    evidenceCollectionDate: "Период заполнения",
                    createdDate: "Дата назначения",
                },
                itemStatusFilter: {
                    all: "Все",
                    onlyBad: "С нарушениями",
                    onlyGood: "Без нарушений",
                },
                itemStatusFilterLabel: "Статус",
                prevImage: "Фото с пред. проверки",
                complaint: "Обращение",
                errors: {
                    loadData: "Не удалось получить информацию о текущем чек-листе!",
                },
            },
            evaluation: {
                title: "Оценка чек-листа",
                toComplaint: "Перейти к обращению",
                createComplaint: "Создать обращение",
                toCheckList: "К чек-листу",
                success: {
                    createComplaint: 'Обращение создано по шагу "%s"',
                    finishEvaluation: "Чек-лист проверен",
                },
                errors: {
                    loadData: "Не удалось получить информацию о чек-листе!",
                    createComplaint: "Не удалось создать обращение по чек-листу!",
                    finishEvaluation: "Не удалось сохранить результаты проверки!",
                },
            },
            outsources: {
                title: "Рейтинг аутсорсов",
                managerRate: "Оценка менеджера",
                inspectorRate: "Оценка инспектора",
            },
        },
        clients: {
            client: {
                title: "Информация о госте",
                noRecords: "Информация не найдена",
                comments: "Заметки оператора",
                highRiskInfo: {
                    highRiskTitle: "Высокий риск",
                    highRiskComment: "Причина",
                    saveButtonTitle: "Сохранить",
                    saveSuccessText: "Успешное обновление высокого риска",
                },
                search: {
                    title: "Поиск гостя",
                    noRecords: "Гости не найдены",
                    mobile: "Мобильное приложение",
                },
                errors: {
                    userNotFound: "Пользователь не найден",
                },
                success: {
                    update: "Данные гостя изменены",
                    contactUpdate: "Контакт гостя изменен",
                    contactRemove: "Контакт гостя удален",
                },
                base: {
                    title: "Общая информация",
                    clientBase: "Информация",
                    mobile: "Мобильное приложение",
                    name: "Имя",
                    category: "Категория",
                    companyUser: "Сотрудник",
                    phone: "Телефон",
                    car: "Машина",
                    createdDate: "Дата создания",
                    createdAt: "Дата регистрации",
                    registrationPromo: "Промокод",
                    isReferralProgramActivated: "Реф. программа",
                    isReferralProgramActivated1: "Сработала",
                    isReferralProgramActivated0: "Не сработала",
                    referrerPhone: "Рекомендатель",
                    bonus: "Количество бонусов",
                    noRecords: "Информация не найдена",
                    editCategory: {
                        title: "Изменить категорию пользователя",
                    },
                },
                complaints: {
                    title: "Обращения",
                    tradePoint: "Кофейня",
                    dateTime: "Дата",
                    complaintStatus: "Статус",
                    clientBonusPoints: "Бонусы",
                    mark: "Оценка",
                    description: "Обращение",
                    noRecords: "Нет данных",
                },
                preOrderCancels: {
                    title: "Отмена предзаказов",
                },
                contacts: {
                    title: "Контакты",
                    addTitle: "Добавить контакт",
                    editTitle: "Редактировать контакт",
                    noRecords: "Нет контактов",
                    actions: {
                        copy: "Скопировать",
                        edit: "Редактировать",
                        delete: "Удалить",
                        copied: 'Текст "%s" скопирован в буфер обмена',
                        whatsApp: "в WhatsApp",
                        telegram: "в Telegram",
                    },
                    deleteConfirm: {
                        title: "Удалить контакт",
                        text: 'Вы уверены, что хотите удалить контакт "%s"',
                    },
                    fields: {
                        contactPhone: "Номер телефона",
                        contactEmail: "E-mail",
                        contactTelegram: "Логин",
                        contactType: "Тип контакта",
                        description: "Описание",
                        isMain: "Предпочтительный способ связи",
                    },
                    errors: {
                        required: "Поле обязательно к заполнению",
                        email: "Укажите корректный Email",
                        phone: "Укажите корректный номер телефона",
                        telegram: "Укажите корректный логин в Telegram",
                    },
                },
                bonusHistory: {
                    title: "История выдачи бонусов",
                    details: "Подробности операции",
                    fields: {
                        category: "Статья",
                        bonus: "Кол-во баллов",
                        phone: "Номер телефона",
                        changeType: "Тип пополнения",
                        updatedUser: "Кто начислил",
                        description: "Комментарий",
                        startDate: "Дата мероприятия",
                        department: "Инициатор/отдел",
                        number: "Номер сертификата",
                    },
                },
                merger: {
                    title: "Объединить карточки",
                    merge: "Объединить",
                    fields: {
                        id: "ID",
                        name: "Имя",
                        contact: "Контакт",
                        createdDate: "Создан",
                        category: "Категория",
                    },
                    success: {
                        merge: "%p#clientCards# объеденины",
                    },
                    errors: {
                        merge: "Ошибка при попытке объединения карточек",
                    },
                },
            },
        },

        offences: {
            edit: {
                title: "Нарушения",
                actions: {
                    editCategory: 'Редактировать категорию "%s"',
                    editItem: 'Редактировать нарушение "%s"',
                    new: "Добавить",
                },
                category: "Категория",
                item: "Нарушение",
                fields: {
                    name: "Название",
                    type: "Тип",
                    points: "Баллы",
                    categoryId: "Категория нарушений",
                },
                success: {
                    editCategory: "Категория нарушения изменена",
                    addCategory: "Категория нарушения добавлена",
                    addItem: "Нарушение добавлено",
                    editItem: "Нарушение изменено",
                    removeItem: 'Нарушение "%s" удалено',
                    new: "Запись добавлена",
                },
                errors: {
                    editCategory: "Ошибка при попытке редактирования категории нарушения",
                    addCategory: "Ошибка при попытке создания категории нарушения",
                    addItem: "Ошибка при попытке создания нарушения",
                    editItem: "Ошибка при попытке редактирования нарушения",
                    removeItem: "Ошибка при попытке удаления нарушения",
                    new: "Ошибка при добавлении записи",
                    name: "Укажите название",
                    points: "Укажите кол-во  баллов",
                    noRecords: "В данной категории еще нет нарушений",
                },
                deleteConfirm: {
                    title: "Удалить нарушение",
                    text: 'Вы уверены, что хотите удалить нарушение "%s"?',
                    okText: "Да, удалить",
                },
            },
        },
        claims: {
            title: {
                main: "Обращения",
                progress: "Обращения в обработке",
                processed: "Обработанные обращения",
                feedbackClarification: "Обращения на уточнении",
                guestCommunication: "Обращения второй линии",
                protest: "Запросы на возврат",
                additionalComments: "Дозапросы менеджера",
            },
            add: "Добавить обращение",
            noRecords: "Обращений не найдено",
            filter: {
                okkFilter: "Отправитель ОУК",
                status: "Статус",
                tradePointId: "Кофейня",
                isRead: "Только непрочитанные",
                search: "Текст для поиска",
                startDate: "Созданы не позднее",
            },
            fields: {
                id: "ID",
                date: "Дата",
                deadline: "Дедлайн",
                tradePoint: "Кофейня",
                status: "Статус",
                operator: "Оператор",
                inspector: "Инспектор",
                info: "Информация",
            },
            info: {
                client: "Гость: %s",
                category: "Категория: %s",
                clientBonusPoints: "Бонусов начислено: %s",
            },
            protest: {
                add: "Не согласен, вернуть в работу",
                confirm: 'Вернуть в "В работе"',
                cancel: "Отказать в возврате",
                reason: "Причина",
                errors: {
                    addProtest: "Не удалось опротестовать решение!",
                    confirmProtest: "Не удалось вернуть в работу!",
                    cancelProtest: "",
                },
                success: {
                    addProtest: "Ваш запрос отправлен! Ожидайте решения в ближайшее время",
                    confirmProtest: 'Обращение возвращено в статус "В работе"',
                    cancelProtest: 'Обращение возвращено в статус "Обработано c нарушениями"',
                    submittedToCommission: "Обращение вынесено на Комиссию",
                },
                submitToCommission: "Вынести на Комиссию",
            },
            additionalComments: {
                add: "Ответить на дозапрос",
                success: {
                    addAdditionalComment: "Дозапрос отправлен",
                    removeAdditionalComment: "Ответ на дозапрос отправлен",
                },
                errors: {
                    addAdditionalComment: "Не удалось отправить дозапрос!",
                    removeAdditionalComment: "Не удалось ответить на дозапрос!",
                },
                manager: {
                    add: "Дозапросить информацию",
                    value: "Каких данных вам не хватает",
                    save: "Отправить",
                },
            },
            edit: {
                orderDetailInfo: {
                    modalTitle: "Информация о деталях заказа",
                    dishName: "Наименование блюда",
                    dishCount: "Количество",
                    dishPrice: "Цена за шт",
                    orderCreateDate: "Время заказа",
                    orderDate: "Дата заказа (примерно)",
                    orderDateHint: "Заказ ищется в диапазоне +-месяц от указанной даты",
                    closeTime: "Время закрытия",
                    noData: "Данные не найдены",
                },
                title: "Обработка обращения",
                editTitle: "%s на кофейне %s",
                addTitle: "Создание обращения",
                params: "Параметры",
                paramsDescription: "Заполните все поля",
                clientDetails: "Информация о госте",
                iikoOrderNumberEdit: "Номер заказа",

                iikoBonusesAdd: {
                    modalTitle: "Добавление бонусов гостю",
                    clientContactsTitle: "Телефон гостя",
                    bonusesCountTitle: "Количество бонусов",
                    successAddBonuses: "Бонусы успешно начислены",
                    errorAddBonuses: "Ошибка при зачислении бонусов",
                    commentAddBonuses: "Бонусов начислено гостю",
                },
                fields: {
                    clientId: "Гость",
                    tradePointId: "Кофейня",
                    tradePointIds: "Кофейни",
                    dateTime: "Время отзыва",
                    dateTimeMobileApp: "Время заказа",
                    categories: "Категории",
                    clientBonusPoints: "Бонусов начислено гостю",
                    clientBonusPointsByOperator: "Бонусов начислено оператором",
                    clientBonusPointsByInspector: "Бонусов начислено инспектором",
                    complaintStatus: "Статус обращения",
                    videoChecksCount: "Проверено видео",
                    videoExtraChecksCount: "Дополнительно проверено видео",
                    complaintDescription: "Описание",
                    files: "Файлы",
                    sourceType: "Источник поступления сообщения",
                    inspector: "Проверяющий",
                    operator: "Оператор",
                    guestCommunicationFinishDate: "Коммуникация с гостем завершена",
                    guestCommunicationStatus: "Коммуникация с гостем",
                    guestCommunicationStatus0: "Не требуется",
                    guestCommunicationStatus1: "Требуется повторная",
                    isOkkQualitySurveyRequired: "Запросить оценку качества обслуживания",
                    okkQualitySurveyStatus: "Оценка качества обслуживания",
                    okkNote: "Заметки ОУК",
                    gptSuitableResponse: "Уместный ответ на обращение (*сгенерирован gpt)",
                    comments: "Обсуждение обращения",
                    tags: "Теги",
                    offenseItemIds: "Нарушения",
                    address: "Адрес",
                    legalPersonName: "Юр. лицо",
                    guestCommunicationImages: "Фото переписки с гостем",
                },
                gptAssistance: {
                    feedbackSuitableResponse: "gpt уместный ответ на обращение...",
                    feedbackSuitableTags: "gpt теги...",
                    feedbackSuitableCategories: "gpt категории...",
                },
                success: {
                    add: "%p#complaints# создано",
                    iikoOrderNotFound: "Заказ найден",
                },
                errors: {
                    load: "Не удалось получить информацию об обращении!",
                    add: "Не удалось создать обращение",
                    reOpenComplaint: "Не удалось переоткрыть обращение",
                    createOkkQualitySurvey: "Не удалось запросить оценку гостем",
                    updateComplaint: "Не удалось отредактировать обращение",
                    haveOffenses:
                        'Нельзя закрыть обращение со статусом "Обработано" так как у него имеются незакрытые нарушения',
                    haveOffensesAction: "Сейчас исправлю",
                    notHaveOffenses: "Необходимо указать нарушения",
                    actionNotFound: "Действие не найдено",
                    loadTags: "Не удалось загрузить список тегов. Попробуйте обновить страницу!",
                    addTag: "Не удалось добавить новый тег!",
                    iikoOrderNotFound: "Заказ не найден",
                    iikoOrderNotChecked: "Заказ не проверен",
                    guestCommunicationImageIds: "Загрузите фото переписки с гостем",
                },
                actions: {
                    save: "Сохранить изменения",
                },
                noFilesText: "Нет загруженных файлов",
            },
            qCCheckListData: {
                title: "Информация о Чек-листе",
                checklist: "Наименование чек-листа",
                checklistItem: "Наименование пункта чек-листа",
                date: "Время прохождения чек-листа",
                requirement: "Требование",
            },
            secretVisitsData: {
                title: "Информация об Анкете",
                checklist: "Наименование анкеты",
                checklistItem: "Наименование пункта анкеты",
                date: "Время прохождения анкеты",
                requirement: "Требование",
            },
            tradePointCheckListData: {
                title: "Информация о Чек-листе",
                checklist: "Наименование чек-листа",
                checklistItem: "Наименование пункта чек-листа",
                date: "Время прохождения чек-листа",
                period: "Период для прохождения чек-листа",
            },
            inputOrderNumber: {
                title: "Номер заказа",
                saveButton: "Сохранить",
            },
            mobileApp: {
                title: "Информация из Мобильного приложения",
                main: "Общее",
                mark: "Оценка",
                orderNumber: "Номер заказа",
                mobileApp: "Мобильное приложение",
                isWindow: "Окно/телефон",
                closeDate: "Время закрытия",
                isFromMobileApp: "Источник заказа",
                orderCreateDate: "Время заказа",
                createdDate: "Время отзыва",
                noCriterias: "Критерии не выбраны",
                criteriaBads: "Отрицательные критерии",
                criteriaGoods: "Положительные критерии",
                files: "Вложения",
                details: "Позиции",
                totalPrice: "Сумма: %p#rubs#",
                additionalGuestSurvey: "Показать результаты дополнительного опроса",
                additionalGuestSurveyTitle: "Результаты опроса от %s",
                comment: "Комментарий",
                guestComment: "Комментарий гостя",
            },
            permission: {
                manager: {
                    toWaitingDecision: "Отправить на проверку",
                    toWaitingDecisionAlert: "Обращение передано на проверку",
                    toInProgress: "Доуточнить комментарии",
                    toInProgressAlert: "Обращение возвращено для уточнения",
                },
                dutyOperator: {
                    close: "Закрыть обращение",
                    closeAlert: "Обращение Закрыто",
                    guestCommunication: "Запросить оценку гостем",
                    guestCommunicationAlert: "Оценка Запрошена",
                    toWaitingDecision: "Отправить на проверку",
                    toWaitingDecisionAlert: "Обращение передано на проверку",
                },
                inspector: {
                    guestCommunication: "Запросить оценку гостем",
                    guestCommunicationAlert: "Оценка Запрошена",
                    reOpen: 'Вернуть в "Ожидает решения"',
                    reOpenAlert: 'Обращение возвращено в статус "Ожидает решения"',
                    toInProgress: "Отправить в работу",
                    toInProgressAlert: "Обращение отправлено в работу",
                    toWaitingDecision: "Отправить на проверку",
                    toWaitingDecisionAlert: "Обращение передано на проверку",
                },
            },
            errors: {
                dataLoad: "Не удалось загрузить список обращений. Попробуйте позже",
            },
            offences: {
                title: "Нарушения",
                points: "%p#scores#",
                add: "Добавить нарушение",
                offence: "Нарушение",
                type: "Тип",
                delete: "Удалить нарушение",
                cancel: "Аннулировать нарушение",
                canceled: "Аннулировано",
                userId: "Сотрудник",
                otherUser: "Другой сотрудник",
                success: {
                    cancel: "Нарушение аннулировано",
                },
                errors: {
                    cancel: "Не удалось аннулировать нарушение",
                },
            },
        },
    },
    operationCards: {
        config: {
            title: "КСО",
            deletedTitle: "Удаленные КСО",
            logs: "История изменений",
            addTitle: "Новый КСО",
            editTitle: "Изменить КСО",
            main: "Основное",
            deletedDate: "Дата удаления",
            restore: "Восстановить",
            copy: {
                title: "Скопировать запись",
                copy: "Копия %s",
            },
            deleteConfirm: {
                title: "Удалить КСО",
                text: 'Вы хотите удалить КСО "%s"?',
                okText: "Да, удалить",
            },
            success: {
                save: "Запись изменена",
                remove: 'Запись "%s" удалена',
                removeStep: "Шаг удален",
                saveOrderSteps: "Порядок шагов изменен",
                restoreRecord: "Запись восстановлена",
                copyRecord: "Запись скопирована",
            },
            errors: {
                loadData: "Не удалось получить записи",
                getRecord: "Не удалось получить запись!",
                save: "Не удалось изменить запись!",
                saveEquipments: "Не удалось изменить список оборудования!",
                saveIngredients: "Не удалось изменить списка ингридиентов!",
                remove: "Не удалось удалить запись!",
                addStep: "Не удалось добавить шаг производства!",
                removeStep: "Не удалось удалить шаг!",
                saveOrderSteps: "Не удалось изменить порядок шагов!",
                restoreRecord: "Не удалось восстановить КСО!",
                copyRecord: "Не удалось скопировать запись!",
            },
            equipments: {
                title: "Оборудование и инвентарь",
                noRecords: "Нет выбранного оборудования",
            },
            ingredients: {
                title: "Ингредиенты",
                noRecords: "Нет выбранных ингредиентов",
                measure: "Количество",
                ingredientResult: "Итоговый выход",
            },
            steps: {
                title: "Шаги производства",
                deleteText: 'Вы уверены, что хотите это удалить шаг "%s"?',
                fields: {
                    order: "№",
                    title: "Наименование",
                    duration: "Время, сек",
                    description: "Описание",
                    successResult: "Показатели успешного выполнения",
                    failResult: "Последствия невыполнения",
                    saveOrder: "Сохранить порядок шагов",
                },
            },
        },
        logs: {
            title: "История изменений",
            errors: {
                loadData: "Не удалось загрузить данные!",
            },
        },
    },
    polls: {
        poll: {
            title: "Опросы",
            fields: {
                title: "Название",
                startDate: "Дата начала опроса",
                endDate: "Дата окончания опроса",
            },
            errors: {
                getList: "Ошибка при получении списка опросов",
                getById: "Ошибка при получении данных опроса!",
                add: "Ошибка при сохранении результата опроса",
            },
            success: {
                add: "Опрос пройден",
            },
            noRowsLabel: "Нет доступных опросов",
            checklistInfo: "Информация о чек-листе",
            evidenceCollector: "Сборщик фото",
            checklistOutsourcePoll: {
                endMessage: {
                    badRate:
                        "Дорогой друг, благодарим тебя за обратную связь. Нам очень жаль, что проверка сформировала у тебя такое впечатление. Мы сейчас же начнем работать над исправлением ситуации. Надеемся, что ты вернешься к нам снова!",
                    goodRate:
                        "Дорогой друг, благодарим тебя за обратную связь. Нам есть над чем поработать и в ближайшее время постараемся исправить ситуацию в лучшую сторону. Надеемся снова встретиться с тобой на проверках!",
                    perfectRate: "Дорогой друг, благодарим тебя за обратную связь. Ждем тебя снова!",
                },
            },
            checklistManagerPoll: {
                endMessage: {
                    badRate:
                        "Коллега, очень жаль, что так получилось. Мы сейчас же начнем работать над исправлением ситуации и проведем с аутсорсом серьезную работу. Спасибо, что не остался в стороне и поделился своими впечатлениями",
                    goodRate:
                        "Дорогой коллега, спасибо, что поделился впечатлениями. Несомненно, есть над чем поработать. Возьмем данного аутсорса под особый контроль",
                    perfectRate: "Дорогой друг, благодарим тебя за обратную связь. Ждем тебя снова!",
                },
            },
            endMessage: "Спасибо за прохождения опроса",
            notFound: "Прохождение опроса не доступно для вас. Возможно вы уже его прошли!",
            toHome: "На главную",
            toBack: "К списку опросов",
        },
        instances: {
            add: "Новый опрос",
            title: "Назначенные опросы",
            oukTitle: "Опросы ОУК",
            noAnswer: "Без ответа",
            fields: {
                title: "Название",
                type: "Тип",
                roles: "Роли",
                description: "Описание",
                startDate: "Дата начала опроса",
                endDate: "Дата окончания опроса",
                count: "Опрошено человек",
                userRolesTitle: "Роли пользователей",
                template: "Шаблон",
            },
            errors: {
                getPollReport: "Не удалось получить результаты опроса!",
                getById: "Ошибка при получении данных опроса",
                add: "Ошибка при добавлении опроса",
                edit: "Ошибка при редактировании опроса",
                remove: "Ошибка при удалении опроса",
                view: "Ошибка при получении информации о опросе",
            },
            success: {
                add: "Опрос добавлен",
                edit: 'Опрос "%s" отредактирован',
                remove: 'Опрос "%s" удален',
            },
            remove: {
                title: "Удалить опрос",
                text: 'Вы уверены, что ходите удалить опрос "%s"?',
                okText: "Да, уверен",
            },
            details: {
                title: "Результат опроса",
            },
        },
        templates: {
            add: "Новый шаблон",
            title: "Шаблоны опросов",
            remove: {
                title: "Удалить опрос",
                text: 'Вы уверены, что ходите удалить опрос "%s"?',
                okText: "Да, уверен",
            },

            fields: {
                description: "Описание",
                type: "Тип опроса",
                endMessage: "Сообщение после прохождения",
                updatedDate: "Изменен",
            },
            errors: {
                add: "Ошибка при добавлении опроса!",
                edit: "Ошибка при редактировании опроса!",
                getById: "Ошибка при получении данных опроса!",
                remove: "Ошибка при удалении опроса!",
                loadData: "Ошибка при получении списка опросов",
            },
            success: {
                add: 'Опрос "%s" добавлен',
                edit: 'Опрос "%s" отредактирован',
                remove: 'Опрос "%s" удален',
            },
            items: {
                title: "Вопросы",
                addItemTitle: "Новый вопрос",
                answers: {
                    title: "Варианты ответов",
                    count: "%s Вариантов ответов",
                },
                fields: {
                    title: "Заголовок",
                    description: "Описание",
                    type: "Тип",
                    isRequired: "Обязательно для заполнения",
                },
                remove: {
                    title: "Удалить вопрос",
                    text: 'Вы уверены, что ходите удалить вопрос "%s"?',
                    okText: "Да, уверен",
                },
                inputProps: {
                    minValue: "Минимальное значение",
                    maxValue: "Максимальное значение",
                    icon: "Изображение",
                    example: "Пример",
                    icons: { star: "Звезда", favorite: "Сердце", coffee: "Чашка кофе" },
                    slider: "от %s до %s",
                },
            },
        },
        report: {
            title: "Отчеты",
            instances: "Выберите опрос",
            selectList: "Выберите опрос из списка",
            download: "Скачать отчет",
        },
    },
    profile: {
        title: "Профиль",
        myProfile: "Мой профиль",
        learning: "Обучение",
        rating: {
            inspectorRate: "Оценка инспектора",
            managerRate: "Оценка менеджера",
            averageRage: "Средняя оценка",
            fields: {
                tradePoint: "Кофейня",
                checklist: "Чек-лист",
                checkDate: "Дата проверки",
                comment: "Комментарий",
                impression: "Общее впечатление",
                photoQuality: "Качество фото",
                rate: "Общая оценка",
            },
        },
        userMenu: {
            title: "Настройки",
            image: "аватар",
            actions: {
                avatar: "Загрузка аватара",
                messagesConfig: "Настройка оповещений",
                password: "Смена пароля",
            },
            errors: {
                setIcon: "Не удалось загрузить изображение!",
                savePassword: "Не удалось изменить пароль!",
            },
            success: {
                savePassword: "Пароль успешно изменен!",
            },
        },
        main: {
            title: "Личные данные",
            birthDate: "Дата рождения",
            workStartDate: "Дата начала работы",
            lastVisitDate: "Последний вход",
        },
        grades: {
            title: "Грейды",
            gradeTree: "Дерево грейдов",
            history: "История",
            noHistory: "Нет шагов с тестами",
            fields: {
                title: "Наименование",
                expirationDate: "Пройти до",
                status: "Статус",
                createdDate: "Дата создания",
                sessionBegin: "Дата начала",
                sessionEnd: "Дата окончания",
                grade: "Грейд",
            },
            errors: {
                getUserHistory: "Не удалось получить информацию о прохождении уроков!",
            },
        },
        examinations: {
            title: "Обучение",
            notAvailable: "Действие не доступно",
            activeExaminations: "Назначенные аттестации",
            historyExaminations: "История",
            noRecords: "У вас еще нет аттестации",
            passed: "Пройдена",
            notPassed: "Не пройдена",
            confirm: {
                text: 'Приступить к прохождению аттестации "%s"?',
                okText: "Да, приступить",
            },
            fields: {
                title: "Наименование",
                createdDate: "Назначена",
                sessionEnd: "Завершена",
                status: "Статус",
                passed: "Результат",
                grade: "Грейд",
                deadline: "Пройти до",
                passRate: "Процент",
                rightAnswerPercent: "Пройдено",
            },
        },
        innerLearning: {
            title: "Обучение",
        },
        courses: {
            title: "Курсы",
            passDate: "Пройден",
            noRecords: "Нет пройденных курсов",
        },
        lessons: {
            title: "Уроки",
            activeLessons: "Назначенные уроки",
            created: "Назначено",
            inProgress: "В процессе",
            unlimited: "Не ограничено",
            notAvailable: "Действие не доступно",
            waitingConfirm: "Урок ожидает подтверждения. Обратитесь к своему менеджеру/сотруднику академии",
            confirm: {
                text: 'Приступить к прохождению урока "%s"?',
                okText: "Да, приступить",
            },
            fields: {
                title: "Наименование",
                expirationDate: "Пройти до",
                status: "Статус",
                createdDate: "Дата назначения",
                sessionBegin: "Дата начала",
                sessionEnd: "Дата окончания",
            },
            historyLessons: "История",
            errors: {
                loadData: "Не удалось получить информацию о прохождении уроков!",
            },
        },
        userRating: {
            title: "Рейтинг",
        },
        editorMenu: {
            changeRole: {
                action: "Сменить роль/дерево грейдов",
                title: "Смена роли и дерева грейдов",
                label: "Роль",
                gradeTreeIds: "Дерево грейдов",
                save: "Сменить",
                success: "У сотрудника изменена роль",
                error: "Ошибка при попытке изменения роли",
            },
            changeTradePoint: {
                action: "Сменить кофейню",
                title: "Перемещение сотрудника",
                label: "Новое место работы",
                save: "Переместить",
                success: "Сотрудник перемещен в другую кофейню",
                error: "Ошибка при попытке перемещения сотрудника в другую кофейню",
            },
            changePassword: {
                action: "Сменить пароль",
                title: "Смена пароля",
                newPassword: "Новый пароль",
                confirmPassword: "Подтвердите пароль",
                save: "Сохранить",
                passwordMismatch: "Пароли не совпадают",
                success: "Пароль успешно изменен",
                error: "Ошибка при смене пароля",
            },
            changeStatus: {
                action: "Уволить",
                title: "Уволить сотрудника",
                text: "Вы уверены, что хотите уволить сотрудника %s?",
                okText: "Да, уверен",
                success: "Сотрудник уволен",
                error: "Ошибка при попытке увольнения сотрудника",
            },
            edit: {
                action: "Редактировать",
            },
        },
        phoneConfirm: {
            confirmed: "Подтвержден",
            notConfirmed: "Не подтвержден",
            title: "Подтверждение номера телефона",
            confirmCode: "Код подтверждения",
            confirmCodeDescription: "Введите сюда код, который пришел вам в СМС",
            text: `<p>Вам необходимо подтвердить, что телефон с номером <strong>%s</strong> принадлежит вам. Для этого, необходимо:</p>
                    <ul>
                        <li>Перейти в нашего <strong>Telegram Бота</strong> по ссылке ниже</li>
                        <li>Написать ему <strong>Привет</strong></li>
                        <li>Выбрать в меню кнопку <strong>Подтвердить номер телефона</strong></li>
                        <li>Подтвердить запрос об <strong>Поделиться контактом</strong></li>
                        <li>Если все сделано верно, вы увидите сообщение <strong>Номер телефона успешно подтвержден</strong></li>
                    </ul>`,
            toTelegramBot: "Перейти в Telegram Бота",

            errors: {
                sendSmsToPhoneConfirm: "Не удалось отправить вам смс!",
                confirmPhoneCode: "Не удалось подтвердить ваш телефон!",
            },
            success: {
                confirmPhoneCode: "Ваш номер телефона успешно подтвержден",
            },
            notMyNumber: {
                title: "Не мой номер телефона",
                text: `<p>Если вы НЕ являетесь владельцем номера <strong>%s</strong>, 
                        то вам необходимо написать E-mail по адресу 
                        <a href="mailto:sd@cmachine.ru?subject=Смена номера %s&body=Номер %s мне не принадлежит. Пожалуйста измените его на ...">sd@cmachine.ru</a>.
                        В письме необходимо указать данный номер <strong>%s</strong>, а так же номер на который нужно изменить его.
                    </p>`,
                button: "Это не мой номер телефона",
            },
            noTelegram: {
                title: "У меня нет Telegram-а",
                text: "Тогда его можно завести. Это не сложно и займет несколько минут.",
                okText: "Хорошо, заведу",
                noText: "Нет возможности завести",
                confirmTitle: "Не могу завести Telegram",
                confirmText: `Это очень печально. Тогда придется отправить вам СМС с кодом активации на номер %s. Вы готовы?`,
                confirmOkText: "Да, отправляйте",
                confirmNoText: "Нет, не надо",
            },
        },
        phoneNumberChanger: {
            title: "Смена номера телефона",
            success: "Номер успешно сменён",
            error: "Не удалось изменить номер",
        },
    },
    regions: {
        title: "Регионы",
        edit: {
            title: "Редактирование региона",
            success: "Регион успешно обновлен",
        },
        add: {
            title: "Создание региона",
            success: "Регион успешно создан",
        },
        delete: {
            confirmText: "Вы действительно хотите удалить %s записей?",
            success: "Записи успешно удалены",
        },
    },
    reports: {
        main: {
            title: "Отчеты по качеству",
            fields: {
                type: "Тип отчета",
                startDate: "Начальная дата",
                endDate: "Конечная дата",
                reportPeriod: "Отчетный период",
                tradePointId: "Кофейня",
            },
            submitBtnText: "Скачать отчет",
            errors: {
                download: 'Ошибка при попытке получения отчета "%s"',
                invalidDateInput: "Введены некорректные даты",
            },
        },
        operationalReports: {
            title: "Операционные отчеты",
            reportType: "Тип отчета",
            reportPeriod: "Месяц",
            errors: {
                download: "Ошибка при попытке получения отчета",
            },
        },
        thirdParty: {
            errors: {
                getReportByTradePoint: "Ошибка при получении данных отчета!",
            },
        },
        uk: {
            title: "Отчеты УК",
            reportType: "Тип отчета",
            errors: {
                download: 'Ошибка при попытке получения отчета "%s"',
            },
        },
        oukReportNotes: {
            title: "Комментарии ОУК",
            fields: {
                dateMonth: "Отчетный период",
                tradePoint: "Кофейня",
                inspector1: "Санитарное состояние",
                inspector2: "Техническое состояние",
                inspector3: "Работа с обращениями",
                comment: "Процессы общие",
            },
            errors: {
                save: "Ошибка при сохранении данных",
            },
            success: {
                save: "Данные сохранены",
            },
        },
    },
    sd: {
        claims: {
            addTitle: "Новая заявка",
            editTitle: "Заявка %s №%s",
            addClaim: "Создать заявку",
            showEmptyCategory: "Показывать пустые категории",
            deleteClaim: {
                title: "Удалить заявку",
                text: 'Вы уверены, что хотите удалить заявку "%s"?',
                okText: "Да, удалить",
                error: "Ошибка при удалении заявки!",
                success: "Заявка удалена",
            },
            listTitle: "Список заявок",
            myViewTitle: "Моя заявка %s №%s",
            myTitle: "Мои заявки",
            tradePointTitle: "Заявки кофейни",
            noResponsible: "Не назначен",
            comments: "Обсуждение",
            noClaims: "Нет заявок",
            selectTradePoint: "Укажите кофейню",
            myResponsible: "Назначенные мне",
            menuTitle: "Действия",
            claims: "Заявки",
            commentIsLock: "Внутреннее сообщение",
            commentIsNotLock: "Сообщение заявителю",
            isLockTooltip: "Внутреннее сообщение",
            userDetails: {
                main: "Основное",
                fullName: "Ф.И.О.",
                tradePointName: "Название",
                phone: "Телефон",
                legalPerson: "Юр. лицо",
                ownerType: "Тип собственности",
                sdServiceType: "Тип обслуживания",
                address: "Адрес",
                role: "Роль",
                tradePoint: "Подразделение",
            },
            statuses: {
                active: "Активные",
                closed: "Закрытые",
            },
            submitBtnText: {
                toInProgress: "Вернуть в работу",
                new: "Взять в работу",
            },
            groups: {
                createdUser: "Заявитель",
                responsible: "Исполнитель",
                main: "Основное",
            },
            fields: {
                id: "Номер",
                createdDate: "Создана в",
                updatedDate: "Обновлена в",
                escalationDate: "Взята в работу",
                createdUser: "Заявитель",
                waitingDate: "Ожидать до",
                tradePointId: "Подразделение заявителя",
                categoryId: "Подгруппа",
                subCategoryId: "Группа",
                title: "Заголовок",
                isCritical: "Срочная",
                isCriticalTitle: "Тип заявки",
                isTariffication: "Платная",
                isTarifficationTitle: "Тарификация",
                status: "Статус",
                responsibleId: "Исполнитель",
                description: "Описание",
                files: "Файлы",
                workHours: "Затрачено часов рабочих",
                extraHours: "Затрачено часов НЕ рабочих",
                tags: "Теги",
                sdDescription: "Заметки",
            },
            success: {
                addClaim: "Заявка создана",
            },
            errors: {
                addClaim: "Не удалось создать заявку!",
                notFound: "Заявка не найдена",
                getUserDetails: "Не найдена информация о пользователе!",
                toInProgressClaim: 'Не удалось перевести заявку в статус "В работе"!',
                updateClaim: "Не удалось изменить заявку!",
                addComment: "Введите текст или приложите файл",
            },
        },
        claimCategories: {
            title: "Категории",
            fields: {
                title: "Название",
                description: "Описание",
                accessFeatureIds: "Доступы",
                parentId: "Родитель",
            },
            addAccessFeature: {
                helperText: "Если не указано, наследуется от родителя",
            },
        },
    },
    secretVisits: {
        template: {
            title: "Шаблоны анкет",
            addTitle: "Новый шаблон",
            editTitle: "Изменить шаблон",
            main: "Название",
            errors: {
                save: "Не удалось сохранить шаблон!",
                getRecord: "Не удалось получить шаблон!",
                remove: "Не удалось удалить шаблон!",
                removeGroup: "Не удалось удалить группа вопросов!",
                reorderGroup: "Не удалось изменить порядок группа вопросов!",
                saveQuestions: "Не удалось сохранить вопрос!",
                removeQuestion: "Не удалось удалить вопрос!",
                reorderQuestions: "Не удалось изменить порядок вопросов!",
            },
            success: {
                add: "Шаблон %s создан",
                edit: "Шаблон %s изменен",
                remove: "Шаблон %s удален",
                addGroup: "Группа вопросов %s создана",
                editGroup: "Группа вопросов %s изменена",
                removeGroup: "Группа вопросов %s удалена",
                reorderGroup: "Порядок групп вопросов изменен",
                saveQuestions: "Вопрос %s изменен",
                removeQuestion: "Вопрос %s удален",
                reorderQuestions: "Порядок вопросов изменен",
            },
            groups: {
                title: "Вопросы",
                count: "Кол-во групп",
                addGroup: "Добавить группу вопросов",
                editGroup: "Редактировать группу вопросов",
                saveOrder: "Сохранить порядок групп вопросов",
                isDragEnabled: "Сортировка групп",
            },
            questions: {
                addTitle: "Новый вопрос",
                editTitle: "Редактировать вопрос",
                group: "Группа вопросов",
                type: "Тип",
                synchronize: "Синхронизация",
                rate: "Вес шага",
                isRequiredComment: "Комментарий - обязателен",
                isDragEnabled: "Сортировка вопросов",
                saveOrder: "Сохранить порядок вопросов",
            },
        },
        checkLists: {
            title: "Анкеты тайного гостя",
            resultTitle: "Результат: %s",
            evaluationTitle: "Оценка: %s",
            checkList: "Заполнение анкеты",
            status: "Статус анкеты",
            noNewRecords: "Нет доступных анкет для заполнения",
            isChanged: "Ответ отредактирован проверяющим",
            editAnswer: "Изменить ответ тайного гостя",
            fields: {
                templateId: "Шаблон анкеты",
                createdDate: "Дата создания",
                evidenceCollectorName: "Тайный гость",
                inspector: "Проверяющий",
                evaluationDate: "Период оценки",
                evidenceCollectionDate: "Период заполнения",
                evaluationFinished: "Дата оценки",
                rate: "Рейтинг",
            },
            add: {
                title: "Назначение анкеты",
            },
            actions: {
                title: "Действия",
                showCheckList: "Заполнить анкету",
                remove: "Удалить анкету",
                add: "Назначить анкету",
                selectCollector: "Назначить тайного покупателя",
                startEvaluation: "Оценить",
                showResult: "Посмотреть",
                toTemplate: "Шаблоны анкет",
            },
            success: {
                assignment: "Назначено анкет: %s",
                remove: "Анкета %s удалена",
                assignUser: "Тайный гость назначен",
                resetUser: "Тайный гость сброшен",
                finishEvaluation: "Анкета проверена",
                updateAnswer: "Ответ отредактирован",
            },
            errors: {
                assignment: "Не удалось назначить анкету!",
                remove: "Не удалось удалить анкету!",
                assignUser: "Не удалось назначить тайного гостя!",
                finishEvaluation: "Не удалось сохранить результаты проверки!",
                updateAnswer: "Не удалось отредактировать ответ!",
            },
            userSelect: {
                title: "Назначить тайного гостя",
                select: "Назначить",
                evidenceCollector: "Тайный гость",
                reset: "Удалить тайного гостя",
                fields: {
                    imageId: "Фото",
                    fullName: "ФИО",
                    role: "Роль",
                    phone: "Телефон",
                },
            },
            steps: {
                comment: "Комментарий",
                answer: "Ваш ответ",
                answerImage: "Загрузите фото",
                tooltip: {
                    toPrev: "Назад",
                    toNext: "Далее",
                },
                actions: {
                    cancel: "Закрыть",
                    save: "Сохранить",
                },
                finish: {
                    success: "Чек-лист отправлен на проверку!",
                    noText: "Нет, пока рано",
                    okText: "Да, хочу",
                    text: "Вы действительно хотите отправить анкету на проверку?",
                },
                success: {
                    finish: "Вы закончили заполнение анкеты",
                    addAnswer: "Ответ добавлен",
                },
                errors: {
                    finish: "Не удалось закончить заполнение анкеты!",
                    addAnswer: "Не удалось добавить ответ!",
                },
            },
        },
    },
    siteBanner: {
        config: {
            title: "Настройка отображения баннеров",
            fields: {
                title: "Подсказка",
                type: "Тип отображения",
                link: "ссылка",
                siteUrl: "Где отображать",
                imageId: "Изображение",
                startDate: "Начало показа",
                endDate: "Окончание показа",
                roles: "Кому показывать",
                rolesHelperText: "Если пусто - то всем",
            },
            alert: {
                normal: "Стандарт",
                success: "Успех",
                warning: "Внимание",
                error: "Ошибка",
                info: "Информация",
            },
            allDate: "Всегда",
            startDate: "С",
            endDate: "По",
        },
    },
    surveys: {
        list: {
            title: "Мои чек-листы",
            status: "Статус",
            statusActive: "Активен",
            noRecordsText: "Чек-листы не найдены",
            alternateUsers: "Назначить заместителя",
            adminAlternateUsers: "Список заместителей",
            startDate: "Можно заполнить %s",
            endDate: "Закончится %s",
            endedDate: "Время для заполнения окончено",
            updatedDate: "Заполнен %s",
            fields: {
                title: "Название",
                status: "Статус",
                tradePoint: "Кофейня",
                date: "Период для заполнения",
                startText: "Информация",
            },
            errors: {
                timePeriod:
                    "Чек-лист не может быть заполнен. Доступное для заполнения время с %s до %s. Проводите проверку вовремя",
                startDiff: 'К заполнению чек-листа "%s" можно приступить %s !',
                statusFailed: 'Вы не успели заполнить чек-лист "%s" вовремя!',
                otherUser: "Чек-лист уже заполняет сотрудник %s!",
            },
        },
        survey: {
            title: "Заполнение чек-листа",
            step: {
                text: "Ваш ответ",
                description: "Комментарий",
                descriptionPlaceholder: "Здесь можно указать дополнительную информацию",
                next: "Далее",
                prev: "Назад",
                end: "Закончить",
                image: "Добавить фото",
                samplePhoto: "Пример фото",
                video: "Добавить видео",
                sampleVideo: "Пример видео",
            },
            answers: {
                title: "Название",
                value: "Ответ",
                description: "Комментарий",
                questionDescription: "Описание",
                status: "Статус",
                date: "Период заполнения",
                updatedDate: "Заполнен",
            },
            errors: {
                loadData: "Не удалось получить чек-лист!",
                statusFailed: 'Данный чек-лист имеет статус "Провален"',
            },
        },
        templates: {
            title: "Шаблоны чек-листов",
            toAssign: "Назначить чек-листы",
            edit: {
                title: "Редактировать чек-лист",
                addTitle: "Создать чек-лист",
                hoursUntilFailsValidate: "Укажите число больше 0",
                main: "Основное",
                fields: {
                    title: "Название",
                    startDate: "Дата начала",
                    startTime: "Время начала",
                    hoursUntilFails: "Время на заполнение (в часах)",
                    userRoleId: "Роль",
                    tradePointIds: "Кофейни",
                    offenceItemIds: "Нарушения",
                    withOffences: "С нарушениями",
                    rRule: "Повторять",
                },
                success: {
                    add: 'Чек лист "%s" создан',
                    edit: 'Чек лист "%s" изменен',
                    templateQuestionAdd: 'Вопрос "%s" добавлен',
                    templateQuestionEdit: 'Вопрос "%s" изменен',
                    templateQuestionRemove: "Вопрос удален",
                },
                errors: {
                    save: "Не удалось отредактировать чек-лист!",
                    loadData: "Не удалось получить чек-лист!",
                    templateQuestionSave: "Не удалось отредактировать вопрос чек-листа!",
                    templateQuestionRemove: "Не удалось удалить вопрос чек-листа!",
                },
                questions: {
                    title: "Вопросы",
                    addTitle: "Новый вопрос",
                    answers: "Варианты ответов",
                    answersCount: "%s Вариантов ответов",
                    fields: {
                        title: "Название",
                        description: "Описание",
                        type: "Тип вопроса",
                        samplePhoto: "Пример фото",
                        sampleVideo: "Пример видео",
                    },
                    confirmDelete: {
                        title: "Удалить вопрос",
                        text: 'Вы уверены, что хотите удалить вопрос "%s"?',
                        okText: "Да, удалить",
                    },
                    errors: {
                        answers: "Укажите варианты ответов",
                        templateQuestionsReordering: "Не удалось изменить порядок вопросов!",
                    },
                },
            },
            fields: {
                title: "Название",
                startDate: "Начало",
                rRule: "Период",
            },
            confirmDelete: {
                title: "Удалить шаблон",
                text: 'Вы уверены, что хотите удалить шаблон "%s"?',
                okText: "Да, удалить",
            },
            success: {
                remove: 'Шаблон "%s" удален',
                toAssign0: "Нет неназначенных чек-листов",
                toAssign: "Назначено: %p#checkList#",
            },
            errors: {
                loadData: "Не удалось получить список шаблонов!",
                remove: "Не удалось удалить шаблон!",
                toAssign: "Не удалось назначить чек-листы",
            },
        },
        result: {
            title: "Результаты заполнения",
            details: "Информация о заполнении",
            noRecordsText: "Нет данных",
            fields: {
                title: "Название",
                status: "Статус",
                date: "Период для заполнения",
                startDate: "С",
                endDate: "По",
                user: "Пользователь",
                tradePoint: "Кофейня",
                roles: "Ответственные",
            },
            deleteConfirm: {
                title: "Удалить чек-листы",
                text: "Вы уверены, что хотите удалить %p#checkList#?",
                okText: "Да, удалить",
            },
            success: {
                removeUserAssignments: "Назначенные чек-листы удалены",
            },
            errors: {
                loadData: "Не удалось загрузить чек-лист!",
                deleteSelected: "Укажите чек-листы для удаления",
                removeUserAssignments: "Не удалось удалить назначенные чек-листы!",
            },
        },
        alternateUsers: {
            title: "Заместители",
            editTitle: "Заместитель",
            addTitle: "Назначить заместителя",
            startDate: "C %s",
            endDate: "По %s",
            list: {
                title: "Список замещений",
                addTitle: "Назначить заместителя",
                editTitle: "Заместитель",
                startDate: "C %s",
                endDate: "По %s",
                fields: {
                    user: "Пользователь",
                    alternateUser: "Заместитель",
                    surveyUid: "Чек-лист",
                    description: "Комментарий",
                    date: "Период",
                    startDate: "Дата начала",
                    endDate: "Дата окончания",
                },
                success: {
                    removeAlternateUser: "Заместитель удален",
                },
                errors: {
                    loadData: "Не удалось получить список заместителей!",
                    getTemplateList: "Не удалось получить список чек-листов!",
                    removeAlternateUser: "Не удалось удалить заместителя!",
                },
            },

            fields: {
                surveyUid: "Чек-лист",
                date: "Период",
                alternateUser: "Заместитель",
                startDate: "Дата начала",
                endDate: "Дата окончания",
                description: "Комментарий",
            },
            success: {
                removeAlternateUser: "Заместитель удален",
            },
            errors: {
                isYou: "Нельзя указывать себя в качестве заместителя",
                removeAlternateUser: "Не удалось удалить заместителя!",
                getTemplateList: "Не удалось получить список шаблонов!",
            },
        },
    },
    tags: {
        title: "Редактор тегов",
        noRecords: "Нет тегов",
        fields: {
            tagsContext: "Тип",
            category: "Категория",
        },
        errors: {
            load: "Не удалось получить список тегов",
            removeTag: "Не удалось удалить тег",
            editTag: "Не удалось отредактировать тег",
            addTag: "Не удалось добавить тег",
        },
        success: {
            removeTag: 'Тег "%s" удален',
            editTag: "Тег изменен",
        },
        remove: {
            title: "Удалить тэг",
            text: 'Вы уверены, что хотите удалить тег "%s"?',
            okText: "Да, удалить",
        },
        edit: {
            title: "Редактировать тэг",
            addTitle: "Добавить тэг",
            fields: {
                tag: "Тэг",
                category: "Категория",
            },
        },
    },
    telegram: {
        title: "Телеграм",
        registered: {
            start: "Перейдите в ",
            bot: "Телеграм бота ",
            end: 'и напишите "Привет" ',
        },
        notRegistered: {
            start: "Вы еще не представлены нашему ",
            bot: "Телеграм боту ",
            end: 'перейдите в него, напишите "Привет" и следуйте инструкциям',
        },
        checker: {
            title: "Проверка данных",
            errors: {
                notRegister:
                    "Вам не удалось представиться системе. Вернитесь назад и попробуйте представиться еще раз!",
                search: "Не найдены необходимые параметры. Попробуйте еще раз",
            },
            success: {
                registration: "Telegram успешно подключен к вашей Учетной записи",
            },
        },
    },
    thirdParty: {
        reports: {
            config: {
                title: "Настройка отчетов",
                titleParams: "Настройка параметров отчетов",
                fields: {
                    reportGuid: "guid",
                    reportName: "Наименование отчета ",
                    reportFolder: "Наименование раздела",
                    reportUrl: "Ссылка",
                    reportType: "Тип отчета",
                    paramName: "Название",
                    thirdPartyReport: "Отчет",
                    isShowedReports: 'Выводить в "Отчеты"',
                    showCategory: "Отображать в",
                },
            },
            metabase: {
                title: "Отчеты по скорости сервиса",
                report: "Отчет",
            },
        },
    },
    tradePointDashboard: {
        title: "Работа кофейни",
        messagesTitle: "Важные сообщения",
        warningTitle: "Истечет в течение 30 минут",
        alarmTitle: "Просроченные продукты",
        noneWarningMessage: "Нет продуктов, срок годности которых скоро истечет",
        noneAlarmMessage: "Нет просроченных продуктов",
        goTo: "Перейти",
    },
    tradePoints: {
        list: {
            addTitle: "Добавление новой кофейни",
            showTitle: "Кофейня",
            title: "Список кофеен",
            main: "Основное",
            loading: "Идет обновление данных. Подождите...",
            showSwitcher: "Вкл/Выкл кофейню",
            mobileApp: {
                title: "Мобильное приложение",
                main: {
                    title: "Основное",
                    isDeleted: "Удалена",
                    isNotDeleted: "Активна",
                    fields: {
                        id: "ID",
                        isDeleted: "Статус",
                        dates: "Дата (создание, изменение)",
                        deliveryRestaurantName: "deliveryRestaurantName",
                        region: "Регион",
                        organizationId: "organizationId",
                        name: "Название",
                        address: "Адрес",
                        phone: "Телефон",
                        tag: "tag",
                        ip: "ip",
                        isOn: "isOn",
                        isAvailable: "isAvailable",
                        type: "Тип",
                        iikoEndpoint: "iikoEndpoint",
                        restaurantGroupId: "restaurantGroupId",
                        emailForOrder: "emailForOrder",
                        workTime: "workTime",
                        iDUWorkTime: "iDUWorkTime",
                        coordinates: "Координаты",
                        paymentTypes: "Типы оплаты",
                        features: "features",
                    },
                },
                cameras: {
                    title: "Камеры",
                    noRecords: "Нет данных",
                },
                closeReasons: {
                    title: "История выключений",
                    noRecords: "Нет данных",
                    fields: {
                        updatedAt: "Дата",
                        reasonTitle: "Название",
                        reason: "Причина",
                        from: "С",
                        to: "По",
                    },
                },
                legalEntity: {
                    title: "Юр. лицо/ИП",
                    noRecords: "Нет данных",
                    addModal: "Добавить Юр. лицо/ИП",
                    legalEntityName: "Название Юр. лицо/ИП",
                    merchant: "Оплата",
                    merchantName: "Тип оплаты",
                    merchantUserName: "Оплата, пользователь",
                    merchantPassword: "Оплата, пароль",
                    addPaymentTypeMPAY: 'Добавить тип оплаты "Онлайн"',
                    success: {
                        addLegalEntity:
                            "Юр. лицо добавлено! Убедитесь, что в ЛК мерчанта на портале поставщика услуг экваринга появилась соответствующая запись.",
                    },
                    errors: {
                        getLegalEntity: "Не удалось получить информацию о юр. лице!",
                        addLegalEntity: "Не удалось создать новое юр. лицо!",
                        merchant: "Не найдена информация об оплате",
                    },
                },
                merchant: {
                    name: "Тип оплаты",
                    userName: "Пользователь",
                    password: "Пароль",
                    errors: {
                        updateMerchant: "Не удалось изменить настройки оплаты!",
                    },
                    success: {
                        updateMerchant: "Настройки оплаты изменены",
                    },
                },
                errors: {
                    getTradePointPayments: "Не удалось получить информацию об оплате кофейни",
                },
            },
            mobileAppDb: {
                title: "Мобильное приложение База",
                main: {
                    title: "Основное",
                    iArrived: "Я подъехал",
                    pointSpeed: "Скорость",
                },
                legalEntity: {
                    title: "Юр. лицо/ИП",
                    name: "Название",
                    paymentSystem: "Тип оплаты",
                    payselectionMerchant: "Данные для оплаты",
                    addTitle: "Добавить юр. лицо",
                },
                payselectionMerchant: {
                    name: "Имя сайта",
                    siteId: "ID сайта",
                    publicKey: "Публичный ключ",
                    secretKey: "Секретный ключ",
                    confirmDelete: 'Вы уверены, что хотите удалить данные для оплаты "%s"?',
                },
                iikoEndPoint: {
                    title: " Система работы с заказами",
                    iikoEndpoint: "Тип",
                    organizationId: " Iiko Transport OrganizationId",
                    terminalGroupId: " Iiko Transport TerminalGroupId",
                },
                cameras: {
                    title: "Камеры",
                    addTitle: "Новая камера",
                    editTitle: "Редактировать камеру",
                    bar: "Бар",
                    kitchen: "Кухня",
                    streamUrl: "Ссылка на видео",
                    isAvailable: "Активна",
                    isNotAvailable: "Не активна",
                    order: "Порядок вывода",
                },
                pointFeatures: {
                    title: "Теги",
                    add: {
                        title: "Добавить тег",
                        pointFeature: "Тэг",
                    },
                },
                success: {
                    onMainChange: "Данные изменены",
                },
                errors: {
                    noIikoId: 'У кофейни не указан "iiko Delivery Terminal Id" во вкладке настройки',
                    getPoint: "Не найдена информация о кофейне",
                    onMainChange: "Не удалось изменить данные!",
                },
            },
            settings: {
                title: "Настройки",
                fields: {
                    iikoId: "iiko Delivery Terminal Id",
                    iikoRestorauntGroupId: "iiko Restaurant Group Id",
                    iikoConceptionId: "iiko Conception Id",
                    isSpeed: "Скорость",
                    bonusCalculationPercent: "Процент от выручки для премии",
                    speedTimeMetric: "Целевое время (сек)",
                    speedStartHour: "Время начала",
                    speedEndHour: "Время окончания",
                    showInQCCheckList: "Выводить в чек-листах ОУК",
                    sdServiceType: "Тип обслуживания",
                    sdDescription: "Заметки",
                    cashRegisterSerialNumber: "Серийный номер ККТ",
                    fiscalStorageExpirationDate: "Срок действия ФН",
                    fiscalOperatorExpirationDate: "Срок действия ОФД",
                },
                groups: {
                    iiko: "Настройки IIKO",
                    speed: "Настройки Скорости",
                    show: "Настройки чек-листов ОУК",
                    sd: "Настройки Service Desk",
                    fiscalData: "ОФД",
                },
                success: {
                    save: "Данные изменены",
                },
                errors: {
                    save: "Не удалось сохранить изменения!",
                },
            },
            legalPerson: {
                title: "Юр. данные",
                noDepartment: "Для кофейни не указано структурное подразделение!",
                toDepartment: "Указать",
                editDepartment: "Редактировать структурное подразделение",
                fields: {
                    title: "Подразделение",
                    legalPersonName: "Юр. лицо/ИП",
                    inn: "ИНН",
                    kpp: "КПП",
                    address: "Фактический адрес осуществления деятельности",
                    contacts: "Официальные контакты",
                },
            },
            employees: {
                title: "Сотрудники",
                noRecords: "Пока нет",
                fields: {
                    fullName: "ФИО",
                    phone: "Номер телефона",
                    role: "Роль",
                },
            },
            fields: {
                name: "Название",
                phone: "Номер телефона",
                fullName: "Наименование кофейни",
                city: "Город",
                address: "Адрес кофейни",
                firstOpenDate: "Дата открытия",
                ownerType: "Тип собственности",
                email: "Email",
                format: "Формат",
                brand: "Бренд",
                contacts: "Данные для рассылки",
                sdServiceType: "Тип обслуживания",
                legalPersonName: "Юр. лицо",
            },
            errors: {
                download: "Ошибка при попытке скачать отчет!",
                add: "Ошибка при попытке добавить кофейню!",
                edit: "Ошибка при попытке отредактировать кофейню!",
                remove: "Ошибка при попытке удалить кофейню!",
                get: "Ошибка при попытке получить данные о кофейне!",
                all: "Ошибка при попытке получить список кофеен!",
            },
            success: {
                add: "Кофейня %s добавлена",
                edit: "Кофейня %s отредактирована",
                remove: "Кофейня удалена",
            },
            deleteConfirm: {
                title: "Удалить кофейню",
                text: 'Вы уверены что хотите удалить кофейню "%s"?',
                okText: "Да, удалить",
            },
        },
        switcher: {
            reasonsList: {
                reasons1: "Санитарная обработка 15 минут",
                reasons2: "Санитарная ночь",
                reasons3: "Ревизия",
                reasons4: "Техническая проблема (кофейня не работает)",
                reasons5: "Прочее",
                reasons6: "Техническая проблема",
            },
            history: {
                title: "История отключения кофейни",
                comment: "Комментарий",
                status: "Статус",
                date: "Дата",
                turnedOn: "Включили",
                turnedOff: "Выключили",
                loading: "Идет получение информации о статусе кофейни. Подождите...",
                deleteOutage: "Удалили запланированное отключение",
                turningTimeOff: "%s Время отключения: c %s по %s",
                outages: "Запланированные отключения",
                errors: {
                    switchHistory: "Не удалось получить историю кофейни",
                    getPlannedOutages: "Не удалось получить запланированные отключения",
                    deleteOutage: "Не удалось удалить запланированное отключение",
                },
            },
            title: "Работа мобильного приложения",
            isOn: "Включен",
            isOff: "Выключен",
            isUnknown: "Нет данных",
            turnOn: {
                action: "Включить",
                confirm: 'Вы уверены, что хотите включить кофейню "%s"?',
            },
            turnOff: {
                action: "Выключить",
                confirm: 'Вы уверены, что хотите выключить кофейню "%s"?',
                comment: "Комментарий",
                reason: "Причина",
                isScheduled: "Отложенное отключение",
                startDate: "Время выключения",
                endDate: "Время включения",
            },
            errors: {
                isOpened: "Не удалось включить кофейню %s!",
                isClosed: "Не удалось выключить кофейню %s!",
                loadData: "Не удалось получить данные!",
            },
            success: {
                isOpened: "Кофейня %s включена",
                isClosed: "Кофейня %s выключена",
            },
        },
    },

    users: {
        title: "Пользователи",
        addTitle: "Добавление сотрудника",
        editTitle: "Просмотр сотрудника",
        edit: "Редактирование сотрудника",
        profile: "Профиль",
        documents: "Документы",
        status: {
            dismissed: {
                title: "Уволить сотрудника",
                text: "Вы уверены, что хотите уволить сотрудника?",
                okText: "Да, уверен",
            },
            working: {
                title: "Взять на работу сотрудника",
                text: "Вы уверены, что хотите взять на работу сотрудника?",
                okText: "Да, взять на работу",
            },
            error: "Ошибка при попытке смены статуса сотрудника!",
        },
        oauth: {
            title: "Авторизация",
            errors: {
                login: "Ошибка авторизации",
            },
        },
        filter: {
            onlyWorking: "Только активные",
        },
        employee: "Работник кофейни",
        admin: "Работник офиса",
        adminPhoneConfirm: {
            title: "Подтверждение номера телефона",
            text: "Вы уверены, что хотите подтвердить номер телефона %s для пользователя %s?",
            okText: "Да, подтвердить",
            success: "Номер подтвержден",
            error: "Не удалось подтвердить номер телефона",
        },
        checker: {
            title: "Введите основную информацию о пользователе",
            status: {
                exist: "Данный пользователь уже есть в базе.",
                blocked:
                    "Данный пользователь уже есть в базе, но заблокирован. Для разблокировки введите новый пароль.",
                otherName: "Данный номер закреплен за пользователем %s.",
                otherNameBlocked: "Данный номер закреплен за заблокированным пользователем %s.",
                adminExist: "Данный номер закреплен за работником офиса.",
            },
            actions: {
                goTo: "Перейти к записи",
                recovery: "Восстановить",
                confirm: "Да это он",
            },
            recovery: {
                error: "При попытке восстановления записи произошла ошибка",
                success: "Пользователь активирован",
            },
            submitBtnText: "Проверить",
            loaderText: "Идет проверка. Подождите...",
        },
        fields: {
            birthDate: "Дата рождения",
            gradeTrees: "Деревья грейдов",
            imageId: "Фото",
            name: "ФИО",
            phone: "Номер телефона",
            oldUserId: "GUID",
            tradePoint: "Кофейня",
            role: "Роль",
            lastName: "Фамилия",
            firstName: "Имя",
            middleName: "Отчество",
            status: "Статус пользователя",
            workStartDate: "Дата устройства на работу",
            email: "E-mail",
            tradePointId: "Кофейня",
            tradePointIds: "Кофейни",
            image: "Фото",
            password: "Пароль",
            accessFeatures: "Доступы",
        },
        forGroups: {
            main: "Основная информация",
            additional: "Дополнительная информация",
            learning: "Обучение",
            password: "Данные авторизации",
            image: "Фото",
        },
        errors: {
            userName: "Данный номер телефона уже есть в базе",
            edit: "Ошибка при попытке отредактировать пользователя!",
            add: "Ошибка при попытке создать пользователя!",
            checkUserExist: "Не удалось проверить пользователя!",
            getUserData: "Не удалось найти информацию о пользователе!",
            insertTradePoint: "Укажите кофейню",
            insertTradePoints: "Укажите кофейни",
            insertGradeTrees: "Укажите дерево грейдов",
        },
        save: {
            edit: "Данные пользователя успешно изменены",
            add: "Пользователь  успешно создан",
        },
        extra: {
            title: "Дополнительно",
            user: {
                title: "Пользователь",

                adminLogin: "Зайти под пользователем",
            },
            learning: {
                title: "Обучение",
                updateUserGrades: "Пересчитать грейды",
                lastGradeCalculationsDate: "Дата последнего пересчета: %s",
            },
            additional: {
                title: "Дополнительные параметры",
                fields: {
                    iikoUserId: "ID пользователя в IIko",
                },
                success: {
                    updateUserAdditionalData: "Данные обновлены",
                },
                errors: {
                    updateUserAdditionalData: "Не удалось отредактировать пользователя!",
                },
            },
            accessFeatures: {
                title: "Уникальные доступы",
                errors: {
                    executeAsync: "Не удалось выполнить запрос!",
                    updateUsersFeatures: "Не удалось обновить доступы!",
                },
                success: {
                    updateUsersFeatures: "Доступы изменены",
                },
            },
            employee: {
                title: "Работник кофейни",
                lastVisitDate: "Последний вход",
                createdDate: "Создан",
            },
            success: {
                updateUserGrades: "Грейды пересчитаны",
                notUpdateUserGrades: "Грейды пересчитаны, ничего не изменилось",
            },
            errors: {
                updateUserGrades: "Ошибка при попытке пересчета грейдов!",
                getAdditionalData: "Не удалось получить данные о пользователе!",
                adminLogin: "Не удалось авторизоваться под пользователем!",
            },
        },
        login: {
            login: "Войти",
            password: "Пароль",
            title: "Войдите в ваш аккаунт",
            phone: "Телефон",
            errors: {
                usernameOrPassword: "Неверный логин или пароль",
                blocked: "Учётная запись заблокирована, обратитесь к своему менеджеру или в службу поддержки",
                default: "Что-то пошло не так. Обратитесь в службу поддержки",
            },
        },
        logout: {
            loaderText: "Идет выход из учетной записи. Подождите...",
        },
    },
};

export default ruPages;

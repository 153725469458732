const ruPipes = {
    mainReportType: {
        okkComplaintsOnCommission: "Повестка к Комиссии",
        feedbackIndexes: "Отчет по индексам обратной связи гостей",
        forPeriodOffences: "Отчет по нарушениям",
        forOkkProcessedComplaints: "Отчет по обработанным обращениям",
        forCountOkkComplaints: "Отчет по обращениям гостей в разрезе категорий",
        forPeriodPreOrderCancels: "Отчёт по отменам предзаказов",
        forPeriodRating: "Отчет по оценкам мобильного приложения",
        forPeriodMobile: "Отчет по рейтингу мобильного приложения",
        tradePointCheckLists: "Отчет по чек-листам кофейни",
        completedQCCheckLists: "Отчет по чек-листам ОУК",
        statusLog: "Отчет по времени статусов обращений",
        discrepancyIndexExcel: "Отчет по индексам несоответствия",
    },
    clientAddBonusType: {
        equal: "Дополнить до значения",
        add: "Прибавить",
    },
    clientCategory: {
        base: "Обычный",
        suspect: "Подозрительный",
        scammer: "Мошенник",
        vip: "VIP",
        blacklist: "Черный список",
    },
    clientBonusStatus: {
        failed: "Неудача",
        success: "Выполнено",
    },
    contactType: {
        phone: "Телефон",
        email: "E-mail",
        telegram: "Telegram",
    },
    documentFieldSynchronizeType: {
        no: "Не синхронизировать",
        lastName: "Фамилия",
        firstName: "Имя",
        middleName: "Отчество",
        birthDate: "Дата рождения",
        sex: "Пол",
    },
    documentStatus: {
        notCreated: "Новый",
        success: "Подтвержден",
        checkout: "На проверке",
        checkoutManager: "На проверке менеджера",
        checkoutHr: "На проверке отдела кадров",
    },
    documentProcessingAction: {
        edit: "Редактировать",
        confirm: "Подтвердить",
    },
    documentProcessingActionUserType: {
        currentUser: "Пользователь",
        manager: "Руководитель",
        hr: "Отдел кадров",
    },
    gradeHistoryType: {
        passed: "Получен",
        annulated: "Аннулирован",
    },
    gradeStepStatus: {
        new: "Новый",
        passed: "Пройден",
        needToRepass: "Нужно пройти",
        inProgress: "В процессе",
        failed: "Провален",
        expired: "Время истекло",
        waitingConfirm: "Ожидает подтверждения",
    },
    gradeStepType: {
        lessonStep: "Урок",
        notificationStep: "Уведомление",
    },
    gradeExpirationWarningType: {
        gradeStep: "Пройдите шаг грейда",
        examination: "Пройдите назначенную аттестацию",
    },
    filterTextEquals: {
        contains: "Содержит",
        notContains: "Не содержит",
        equals: "Равен",
        startWith: "Начинается с",
        endWith: "Заканчивается на",
        isClear: "Нет значения",
        isNotClear: "Есть значение",
    },
    filterNumberEquals: {
        contains: "Содержит",
        notContains: "Не содержит",
        equals: "Равно",
        more: ">=",
        less: "<=",
        notEquals: "Не равно",
    },
    filterDateEquals: {
        equals: "Равно",
        more: ">=",
        less: "<=",
        notEquals: "Не равно",
    },
    ingredientType: {
        raw: "Сырье",
        finished: "Продукт",
        semiFinished: "Полуфабрикат",
    },
    journalQuestionType: {
        text: "Текст",
        date: "Дата",
        image: "Фото",
        yesNo: "Да/Нет",
        number: "Число",
        select: "Список",
    },
    journalAssignmentStatus: {
        new: "Доступный",
        completed: "Заполненный",
    },
    lessonStepType: {
        test: "Тест",
        poll: "Опрос",
        common: "Информационный",
        multiTest: "Мульти-тест",
        rightOrder: "Правильный порядок",
        textAnswer: "Текстовый ответ",
    },
    messageStatus: {
        none: "Отключен",
        new: "Новое",
        readed: "Прочитано",
        failed: "Ошибка",
    },
    operationalReportType: {
        tradePoint: "Сводный отчет по кофейне",
    },

    pointIikoEndpoint: {
        biz: "Iiko biz (старая)",
        cloud: "Iiko api (Transport)",
    },
    tableLogTableName: {
        operationCard: "КСО",
    },
    thirdPartyReportShowCategory: {
        speed: "Отчеты по скорости сервиса",
        operationalReport: "Операционные отчеты",
        ukReport: "Отчеты УК",
    },
    tradePointFormat: {
        autocafe: "Автокафе",
        coffeeIsland: "Кофе-остров",
        cafe: "Кафе",
        foodTruck: "Фуд-трак",
        stop: "Остановка",
        foodCourt: "Фудкорт",
    },
    tradePointBrand: {
        coffeeMachine: "Кофе Машина",
        yaiThai: "Яй Тай",
        crossCulture: "Кросс Культура",
        invigorate: "Бодрю",
    },
    roles: {
        SuperAdmin: "Супер админ",
        CommonEmployee: "Универсал",
        TradePointManager: "Менеджер",
        OfficeWorker: "Офисный сотрудник",
        Franchisee: "Франчайзи",
        TrainingTablet: "Планшет",
        TerritorialDirector: "Территориальный директор",
        Inspector: "Инспектор",
        Trainer: "Тренер",
        ManagerAssistant: "Кассир",
        BaristaAssistant: "Помощник бариста",
        Barista: "Бариста",
        Producer: "Заготовщик",
        Cook: "Повар",
        Steward: "Официант",
        Probationer: "Стажер",
        PortalAdministrator: "Администратор портала",
        ShiftSupervisor: "Старший смены",
        ExternalEvidenceCollector: "Внештатный сборщик фото",
        TradePointTablet: "Планшет кофейни",
        ChiefInspector: "Ведущий инспектор",
        DutyOperator: "Дежурный оператор",
        Marketer: "Маркетолог",
        Technologist: "Технолог",
        OperatingDirector: "Операционный директор",
        UkLeader: "Руководитель УК",
        noRole: "Без роли",
        Academy: "Сотрудник Академии",
    },
    roleTypes: {
        Employees: "Работник кофейни",
        NoTradePoints: "Работник УК",
        ManyTradePoints: "Другое",
    },
    sdClaimStatus: {
        new: "Новая",
        inProgress: "В работе",
        closed: "Закрыта",
        waitingBefore: "Отложена до",
        waitingClose: "Ожидает закрытие",
    },
    sdServiceType: {
        claim: "По заявкам",
        contract: "По договору",
    },
    lessonConfirmType: {
        no: "Не требуется",
        manager: "Менеджером",
        admin: "Администратором",
    },
    lessonLearnSessionStatus: {
        created: "Назначено",
        inProgress: "В процессе",
        completed: "Завершено",
        failed: "Провалено",
        expired: "Истекло",
        terminated: "Отменено",
        waitingConfirm: "Ожидает подтверждения",
    },
    tradePointOwnerType: {
        onControl: "Под управлением",
        own: "В собственности",
        franchise: "Франшиза",
    },
    userStatus: {
        working: "Работает",
        dismissed: "Уволен",
    },
    offenseType: {
        main: "Основное",
        concomitant: "Сопутствующее",
    },
    okkComplaintStatus: {
        notProcessed: "Необработанно",
        processedWithOffense: "Обработано c нарушениями",
        processed: "Обработано",
        protest: "Опротестовано",
        inProgressByInspector: "В работе у инспектора",
        inProgressByManager: "В работе у менеджера",
        inProgress: "В работе",
        feedbackClarification: "На уточнении",
        waitingDecision: "Ожидает решения",
    },
    preOrderCancelReason: {
        didntPickUp: "Не забрал",
        cancelInAppOrPhone: "Отменил",
    },
    pollType: {
        nPS: "NPS",
        base: "Базовый",
        noInstance: "Без назначения",
        qCCheckList: "Чек-лист ОУК",
    },
    okkSourceType: {
        mobileApp: "Мобильное приложение",
        video: "Видео",
        videoServiceControl: "Сервис видеонаблюдения",
        stp: "Проверка",
        phone: "Телефон",
        telegram: "Telegram",
        whatsApp: "WhatsApp",
        instagram: "Instagram",
        qCCheckList: "Чек-лист ОУК",
        tradePointCheckList: "Чек-лист кофейни",
        secretVisits: "Проверка тайным гостем",
        email: "Электронная почта",
        other: "Прочие источники поступления обращения",
    },
    okkSourceTypeTitle: {
        mobileApp: "Отзыв из мобильного приложения",
        video: "Жалоба из видео",
        videoServiceControl: "Жалоба из сервиса видеонаблюдения",
        stp: "Проверка",
        phone: "Жалоба из телефонного звонка",
        telegram: "Жалоба из Telegram",
        whatsApp: "Жалоба из WhatsApp",
        instagram: "Жалоба из Instagram",
        qCCheckList: "Чек-лист ОУК",
        tradePointCheckList: "Чек-лист",
        email: "Жалоба по электронной почте",
        secretVisits: "Проверка тайным гостем",
        other: "Обращение",
    },
    okkQualitySurveyStatus: {
        notProcessed: "Необработанно",
        inProgress: "В процессе",
        successed: "Выполнено",
        failed: "Провалено",
    },
    pollItemType: {
        text: "Текст",
        list: "Список",
        multiList: "Список ( несколько вариантов )",
        slider: "Число от до",
        rating: "Рейтинг",
    },

    initiatorType: {
        self: "Самостоятельно",
        assigned: "Назначено",
        system: "Система",
    },
    qCCheckListPenalizationType: {
        item: "Каждый пункт",
        checkList: "Весь чек-лист",
    },
    qCCheckListComplaintCreationMode: {
        auto: "Автоматически",
        manual: "Вручную",
    },
    qCCheckListStatus: {
        new: "Для заполнения",
        evidenceCollected: "На оценке",
        evaluationFinished: "Обработан",
    },
    qCCheckListItemEvaluationStatus: {
        new: "Новый",
        ok: "Без Нарушений",
        nok: "С нарушениями",
        suspicious: "Под вопросом",
    },
    surveyQuestionType: {
        text: "Текст",
        yesNo: "да/нет",
        list: "Список",
        image: "Фото",
        imageFile: "Фото из галереи",
        video: "Видео",
        videoFile: "Видео из галереи",
    },
    surveyStatus: {
        created: "Назначен",
        inProgress: "В процессе",
        completed: "Завершен",
        failed: "Провален",
    },
    tagsContext: {
        okkComplaint: "Обращения",
    },
    gradeUserStatus: {
        active: "Получен",
        warnings: "Обратите внимание",
        blocked: "Заблокирован",
        failed: "Нужно пройти",
    },
    permissions: {
        camera: "Доступ к камере",
    },
    ukReportType: {
        eNPSReport: "Отчет по опросу ENPS",
    },
    learningReportType: {
        assignedLessonsResultEmployee: "Прохождение назначенных уроков работниками кофеен",
        gradeLessonsResultEmployee: "Прохождение грейдовых уроков работниками кофеен",
        examinationsResult: "Аттестации",
        tradePointsUsersGrades: "Получение грейдов",
        adminsUsersGrades: "Получение грейдов Офис",
        tradePointsRolesGrades: "Грейды по кофейням",
        lessonsResultOfficeWorkers: "Прохождение уроков офисными сотрудниками",
        lessonPollsResult: "Отчет по опросам в уроках",
        gradeTreeLessons: "Наполненность грейдов уроками",
    },
    learningErrorFeedbackStatus: {
        active: "Новая",
        completed: "Исправлено",
        canceled: "Отменено",
    },
    mobileAppPointType: {
        none: "Нет",
        coffemachine: "Автокафе",
        bakery: "Пекарня",
        island: "Кофе-остров",
        crossCulture: "Кросс-культура",
        bodry: "Бодрю",
    },
    innerLearningPersonPlanStatus: {
        waiting: "Ожидает подтверждения",
        confirmed: "Подтверждено",
        cancelled: "Отменено",
    },
    innerLearningActivityStatus: {
        ready: "Выполнено",
        notReady: "Ожидает выполнения",
        cancelled: "Отменено",
    },
    innerLearningAttestationStatus: {
        complete: "Пройдена",
        notComplete: "Ожидает выполнения",
        cancelled: "Провалена",
    },
    userSex: {
        female: "Женский",
        male: "Мужской",
    },
};

export default ruPipes;
